import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Menu from './Menu';
import { useMediaQuery } from '../utils/useMediaQuery';
const Layout = ({ children }) => {
  const isMobile = useMediaQuery('(max-width: 1160px)');
  console.log(isMobile);

  const [showMenu, setShowMenu] = useState(false);
  return (
    <>
      <div className="layout">
        {isMobile && (
          <div className="" style={{ position: 'fixed', height: '64px', width: '100vw', borderBottom: '1px solid rgba(181, 194, 217, 0.5)', background: '#fff', zIndex: '100' }}>
            {' '}
            <div
              style={{ marginTop: '15px', marginLeft: '20px', cursor: 'pointer' }}
              onClick={() => {
                setShowMenu(!showMenu);
              }}>
              <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 18L20 18" stroke="rgba(0,0,0,0.5)" stroke-width="2" stroke-linecap="round" />
                <path d="M4 12L20 12" stroke="rgba(0,0,0,0.5)" stroke-width="2" stroke-linecap="round" />
                <path d="M4 6L20 6" stroke="rgba(0,0,0,0.5)" stroke-width="2" stroke-linecap="round" />
              </svg>
            </div>
          </div>
        )}
        <Menu showMenu={showMenu} setShowMenu={setShowMenu} />
        <div className=""> {children}</div>
      </div>
    </>
  );
};

export default Layout;
