import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useMediaQuery } from '../utils/useMediaQuery';
const Menu = ({ showMenu, setShowMenu }) => {
  const menu = [
    {
      name: 'API помощники',
      link: '/api-pomoshhniki',
      isChild: false,
    },
    {
      name: 'Валидация списка статусов',
      link: '/pomoshhnik-validacziya-spiska-statusov',
      isChild: true,
      type: 'post',
    },
    {
      name: 'Валидация списка товаров',
      link: '/pomoshhnik-validacziya-spiska-tovarov',
      isChild: true,
      type: 'post',
    },
    {
      name: 'Подключение к боту',
      link: '/',
      isChild: false,
    },
    {
      name: '1. Получение заказов из бота  (хук)',
      link: '/poluchenie-zakazov-iz-bota-huk',
      isChild: true,
      type: 'post',
    },
    {
      name: '2. Отправка ваших статусов',
      link: '/otpravka-vashih-statusov',
      isChild: true,
      type: 'get',
    },
    {
      name: '3.  Отправка статусов заказа',
      link: '/otpravka-statusov-zakaza',
      isChild: true,
      type: 'post',
    },
    {
      name: '4. Отправка актуальных данных по играм, серверам и пакетам',
      link: '/otpravka-aktualnyh-dannyh-po-igram-serveram-i-paketam',
      isChild: true,
      type: 'get',
    },
  ];
  const isMobile = useMediaQuery('(max-width: 1160px)');
  const { pathname } = useLocation();
  return (
    <>
      <div
        className={`overlay ${showMenu && 'show'}`}
        onClick={() => {
          setShowMenu(false);
        }}></div>
      <div className={`sidebar ${showMenu && 'show'}`}>
        <div class=" sl-mb-4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '16px 16px 0 16px' }}>
          <h1 class="sl-text-4xl sl-leading-tight sl-font-prose sl-font-semibold  sl-text-heading">Malinka</h1>
          {isMobile && (
            <div
              className="close"
              style={{ marginBottom: '-5px', opacity: '0.4', cursor: 'pointer' }}
              onClick={() => {
                setShowMenu(false);
              }}>
              <svg fill="#000000" width="30px" height="30px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <title>ionicons-v5-m</title>
                <path d="M289.94,256l95-95A24,24,0,0,0,351,127l-95,95-95-95A24,24,0,0,0,127,161l95,95-95,95A24,24,0,1,0,161,385l95-95,95,95A24,24,0,0,0,385,351Z" />
              </svg>
            </div>
          )}
        </div>
        <div className="sl-text-sm sl-leading-relaxed sl-tracking-wide sl-font-bold sl-uppercase sl-mt-6 sl-mb-2 sl-pl-4">Документация</div>
        {menu.map((item) => {
          if (item?.isChild) {
            return (
              <Link
                className="ElementsTableOfContentsItem sl-block sl-no-underline"
                to={item?.link}
                onClick={() => {
                  setShowMenu(false);
                }}>
                <div id="sl-toc-a1ljrnyrqjigt-pomoshhnik-validacziya-spiska-statusov" title="[Помощник] Валидация списка статусов" className={`${pathname == item?.link && 'sl-bg-primary-tint'} sl-flex sl-items-center sl-h-md sl-pr-4 sl-pl-8 hover:sl-bg-canvas-100 sl-cursor-pointer sl-select-none`}>
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bullseye" className="svg-inline--fa fa-bullseye sl-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ color: item?.type == 'post' ? 'hsla(202, 100%, 55%, 1)' : 'hsla(156, 95%, 37%, 1)' }}>
                    <path
                      fill="currentColor"
                      d="M288 256C288 273.7 273.7 288 256 288C238.3 288 224 273.7 224 256C224 238.3 238.3 224 256 224C273.7 224 288 238.3 288 256zM112 256C112 176.5 176.5 112 256 112C335.5 112 400 176.5 400 256C400 335.5 335.5 400 256 400C176.5 400 112 335.5 112 256zM256 336C300.2 336 336 300.2 336 256C336 211.8 300.2 176 256 176C211.8 176 176 211.8 176 256C176 300.2 211.8 336 256 336zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 64C149.1 64 64 149.1 64 256C64 362 149.1 448 256 448C362 448 448 362 448 256C448 149.1 362 64 256 64z"
                    />
                  </svg>
                  <div className="sl-flex-1 sl-items-center sl-text-base sl-truncate sl-mr-1.5 sl-ml-1.5">{item?.name}</div>
                  <div className="sl-flex sl-items-center sl-text-xs">
                    <div className={`sl-font-medium sl-uppercase sl-text-${item?.type == 'post' ? 'primary' : 'success'}`}>{item?.type}</div>
                  </div>
                </div>
              </Link>
            );
          } else {
            return (
              <Link
                className="ElementsTableOfContentsItem sl-block sl-no-underline"
                to={item?.link}
                onClick={() => {
                  setShowMenu(false);
                }}>
                <div title={item?.name} className={`${pathname == item?.link && 'sl-bg-primary-tint'} sl-flex sl-items-center sl-h-md sl-pr-4 sl-pl-4  hover:sl-bg-canvas-100 sl-cursor-pointer sl-select-none`}>
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cloud" className="svg-inline--fa fa-cloud sl-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" style={{ color: 'hsla(20, 90%, 56%, 1)' }}>
                    <path
                      fill="currentColor"
                      d="M96.2 200.1C96.07 197.4 96 194.7 96 192C96 103.6 167.6 32 256 32C315.3 32 367 64.25 394.7 112.2C409.9 101.1 428.3 96 448 96C501 96 544 138.1 544 192C544 204.2 541.7 215.8 537.6 226.6C596 238.4 640 290.1 640 352C640 422.7 582.7 480 512 480H144C64.47 480 0 415.5 0 336C0 273.2 40.17 219.8 96.2 200.1z"
                    />
                  </svg>
                  <div className="sl-flex-1 sl-items-center sl-text-base sl-truncate sl-mr-1.5 sl-ml-1.5">{item?.name}</div>
                </div>
              </Link>
            );
          }
        })}
      </div>
    </>
  );
};

export default Menu;
