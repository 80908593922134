import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
const StartPage = () => {
  return (
    <>
      <div data-test="project-page" className="sl-w-full sl-mx-auto sl-px-20 sl-pt-20 sl-pb-8" style={{ maxWidth: '1500px' }}>
        <div className="HttpService sl-mb-10">
          <div className="sl-flex sl-justify-between sl-items-center">
            <div className="sl-relative">
              <h1 className="sl-text-5xl sl-leading-tight sl-font-prose sl-font-semibold sl-mb-4 sl-text-heading">Подключение к боту</h1>
            </div>
          </div>
          <div className="sl-stack sl-stack--vertical sl-stack--6 sl-flex sl-flex-col sl-items-stretch">
            <div data-test="servers" className="sl-panel sl-outline-none BaseURLContent sl-inverted sl-w-full sl-rounded-lg">
              <div className="sl-panel__titlebar sl-flex sl-items-center sl-relative focus:sl-z-10 sl-text-base sl-leading-none sl-whitespace-nowrap sl-pr-4 sl-pl-4 sl-bg-canvas-200 sl-text-body sl-border-input focus:sl-border-primary sl-select-none">
                <div className="sl-flex sl-flex-1 sl-items-center sl-h-lg">API Base URL</div>
              </div>
              <div className="sl-panel__content-wrapper sl-bg-canvas-100">
                <div className="sl-flex sl-flex-col sl-w-full sl-p-4">
                  <div className="sl-stack sl-stack--vertical sl-stack--1 sl-flex sl-flex-col sl-items-stretch">
                    <div data-test="server-row" className="sl-panel sl-outline-none ServerInfo sl-w-full">
                      <div className="sl-panel__titlebar sl-flex sl-items-center sl-relative focus:sl-z-10 sl-text-base sl-leading-none sl-whitespace-nowrap sl-pr-4 sl-pl-4 sl-bg-canvas-200 sl-text-body sl-border-input focus:sl-border-primary sl-select-none">
                        <div className="sl-flex sl-flex-1 sl-items-center sl-h-lg">
                          <span className="sl-font-bold sl-pr-2 sl-pl-0">Ваш домен:</span>
                          <span aria-label="Ваш домен" className="sl-whitespace-normal sl-py-2" style={{ wordBreak: 'break-word' }}>
                            <span className="sl-font-normal">https://your.domain.com/api</span>
                          </span>
                        </div>
                      </div>
                      <div className="sl-panel__content-wrapper sl-bg-canvas-100" />
                    </div>
                    <div className="sl-self-stretch sl-border-t" />
                    <div data-test="server-row" className="sl-panel sl-outline-none ServerInfo sl-w-full">
                      <div className="sl-panel__titlebar sl-flex sl-items-center sl-relative focus:sl-z-10 sl-text-base sl-leading-none sl-whitespace-nowrap sl-pr-4 sl-pl-4 sl-bg-canvas-200 sl-text-body sl-border-input focus:sl-border-primary sl-select-none">
                        <div className="sl-flex sl-flex-1 sl-items-center sl-h-lg">
                          <span className="sl-font-bold sl-pr-2 sl-pl-0">Наш домен:</span>
                          <span aria-label="Наш домен" className="sl-whitespace-normal sl-py-2" style={{ wordBreak: 'break-word' }}>
                            <span className="sl-font-normal">https://malinka.ru/api</span>
                          </span>
                        </div>
                      </div>
                      <div className="sl-panel__content-wrapper sl-bg-canvas-100" />
                    </div>
                    <div className="sl-self-stretch sl-border-t" />
                    <div data-test="server-row" className="sl-panel sl-outline-none ServerInfo sl-w-full">
                      <div className="sl-panel__titlebar sl-flex sl-items-center sl-relative focus:sl-z-10 sl-text-base sl-leading-none sl-whitespace-nowrap sl-pr-4 sl-pl-4 sl-bg-canvas-200 sl-text-body sl-border-input focus:sl-border-primary sl-select-none">
                        <div className="sl-flex sl-flex-1 sl-items-center sl-h-lg">
                          <span className="sl-font-bold sl-pr-2 sl-pl-0">Mock Server:</span>
                          <span aria-label="Mock Server" className="sl-whitespace-normal sl-py-2" style={{ wordBreak: 'break-word' }}>
                            <span className="sl-font-normal">https://stoplight.io/mocks/malinka/malinka/354292162</span>
                          </span>
                        </div>
                      </div>
                      <div className="sl-panel__content-wrapper sl-bg-canvas-100" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div data-test="security" />
            <div data-test="additional-info" />
          </div>
          <div className="sl-relative">
            <div className="sl-prose sl-markdown-viewer sl-my-5">
              <p>В данном разделе идет пошаговая инстркуция по созданию API для взаимодействия с ботом.</p>
              <h2 id="старт" className="sl-link-heading sl-text-4xl sl-leading-tight sl-font-prose sl-font-bold sl-text-heading">
                <a href="#старт" className="sl-link sl-link-heading__link sl-inline-flex sl-items-center sl-text-current">
                  <div>Старт</div>
                  <div className="sl-link-heading__icon sl-text-base sl-ml-4 sl-text-muted">
                    <i role="img" aria-hidden="true" className="sl-icon fal fa-link" />
                  </div>
                </a>
              </h2>
              <p>
                Перед началом работы, необходимо наличие <strong>приватного ключа</strong> и <strong>ID магазина</strong> (свяжитесь с <a href="url">@telegram</a> для их получения).
                <br />
                Они нужны для проверки запроса на подлиность и создания сигнатуры.
              </p>
              <p>
                Пример приватного ключа:
                <code className="sl-font-mono sl-font-medium sl-mx-0.5 sl-px-1 sl-py-0.5 sl-bg-code sl-text-on-code sl-rounded sl-border" style={{ fontSize: '0.8125em' }}>
                  je1c0xy2R7wY7dPzYB5ikklKofTVBhQP3EmvjHAWaia3fRn3c2
                </code>
                <br />
                Пример ID магазина:
                <code className="sl-font-mono sl-font-medium sl-mx-0.5 sl-px-1 sl-py-0.5 sl-bg-code sl-text-on-code sl-rounded sl-border" style={{ fontSize: '0.8125em' }}>
                  6943469552
                </code>
              </p>
              <h2 id="авторизация" className="sl-link-heading sl-text-4xl sl-leading-tight sl-font-prose sl-font-bold sl-text-heading">
                <a href="#авторизация" className="sl-link sl-link-heading__link sl-inline-flex sl-items-center sl-text-current">
                  <div>Авторизация</div>
                  <div className="sl-link-heading__icon sl-text-base sl-ml-4 sl-text-muted">
                    <i role="img" aria-hidden="true" className="sl-icon fal fa-link" />
                  </div>
                </a>
              </h2>
              <p>
                Под авторизацией подразумевается формаирование и проверка сигнатуры, которое должно находится в header запроса <br />{' '}
                <code className="sl-font-mono sl-font-medium sl-mx-0.5 sl-px-1 sl-py-0.5 sl-bg-code sl-text-on-code sl-rounded sl-border" style={{ fontSize: '0.8125em' }}>
                  Signature: 69572a9500f04734b3655e6973c393bb9861dcd55c7e8e1985be122c102254d6
                </code>
                .
              </p>
              <ol>
                <li>
                  Для <strong>хука</strong> необходимо сформировать сигнатуру <strong>из тела запроса</strong> и <strong>приватного ключа</strong> и сверить ее с <strong>сигнатурой которая пришла в заголовке запроса</strong>.
                </li>
                <li>
                  Для <strong>остальных запросов</strong> необходимо создать сигнатуру из <strong>ID магазина</strong> и <strong>приватного ключа</strong> и передать в <strong>header</strong> запрос
                </li>
              </ol>
              <h3 id="проверка-сигнатуры-хука" className="sl-link-heading sl-text-2xl sl-leading-snug sl-font-prose sl-font-semibold sl-text-heading">
                <a href="#проверка-сигнатуры-хука" className="sl-link sl-link-heading__link sl-inline-flex sl-items-center sl-text-current">
                  <div>Проверка сигнатуры хука</div>
                  <div className="sl-link-heading__icon sl-text-base sl-ml-4 sl-text-muted">
                    <i role="img" aria-hidden="true" className="sl-icon fal fa-link" />
                  </div>
                </a>
              </h3>
              <p>
                Сигнатура хука формируется из <strong>тело запроса</strong> и <strong>приватного ключа</strong>.
                <br /> Пример тела запроса:
              </p>
              <pre tabIndex={0} className="sl-code-viewer sl-grid sl-inverted sl-overflow-x-hidden sl-overflow-y-hidden sl-relative sl-bg-canvas sl-outline-none sl-rounded-lg focus:sl-ring sl-ring-primary sl-ring-opacity-50 sl-group" role="group">
                <div className="sl-code-viewer__scroller sl-overflow-x-auto sl-overflow-y-auto" style={{ maxHeight: '500px' }}>
                  <div className="sl-code-highlight prism-code language-json" style={{ padding: '12px 15px', fontFamily: 'var(--font-code)', fontSize: 'var(--fs-code)', lineHeight: 'var(--lh-code)' }}>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          {'{'}
                        </span>
                        <span className="token plain" />
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain">{'  '}orderId</span>
                        <span className="token operator" style={{ color: 'rgb(255, 123, 114)' }}>
                          :
                        </span>
                        <span className="token plain"> '</span>
                        <span className="token number" style={{ color: 'rgb(247, 140, 108)' }}>
                          7877
                        </span>
                        <span className="token number" style={{ color: 'rgb(247, 140, 108)' }}>
                          -453513
                        </span>
                        <span className="token number" style={{ color: 'rgb(247, 140, 108)' }}>
                          -8790
                        </span>
                        <span className="token plain">'</span>
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          ,
                        </span>
                        <span className="token plain" />
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain">{'  '}serverId</span>
                        <span className="token operator" style={{ color: 'rgb(255, 123, 114)' }}>
                          :
                        </span>
                        <span className="token plain"> 'Europe'</span>
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          ,
                        </span>
                        <span className="token plain" />
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain">{'  '}playerId</span>
                        <span className="token operator" style={{ color: 'rgb(255, 123, 114)' }}>
                          :
                        </span>
                        <span className="token plain"> 'gen_l'</span>
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          ,
                        </span>
                        <span className="token plain" />
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain">{'  '}packageId</span>
                        <span className="token operator" style={{ color: 'rgb(255, 123, 114)' }}>
                          :
                        </span>
                        <span className="token plain"> '</span>
                        <span className="token number" style={{ color: 'rgb(247, 140, 108)' }}>
                          6
                        </span>
                        <span className="token plain">'</span>
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          ,
                        </span>
                        <span className="token plain" />
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain">{'  '}gameId</span>
                        <span className="token operator" style={{ color: 'rgb(255, 123, 114)' }}>
                          :
                        </span>
                        <span className="token plain"> '</span>
                        <span className="token number" style={{ color: 'rgb(247, 140, 108)' }}>
                          2
                        </span>
                        <span className="token plain">'</span>
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          ,
                        </span>
                        <span className="token plain" />
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain">{'  '}externalAccountId</span>
                        <span className="token operator" style={{ color: 'rgb(255, 123, 114)' }}>
                          :
                        </span>
                        <span className="token plain"> '</span>
                        <span className="token number" style={{ color: 'rgb(247, 140, 108)' }}>
                          111
                        </span>
                        <span className="token plain">'</span>
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain" />
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          {'}'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-testid="copy-button" className="sl-absolute sl-right-0 sl-pr-2 sl-invisible group-hover:sl-visible sl-invisible" style={{ top: '9px' }}>
                  <button type="button" className="sl-button sl-form-group-border sl-h-sm sl-text-base sl-font-medium sl-px-1.5 hover:sl-bg-canvas-50 active:sl-bg-canvas-100 sl-text-muted hover:sl-text-body focus:sl-text-body sl-rounded sl-border-transparent sl-border disabled:sl-opacity-70">
                    <div className="sl-mx-0">
                      <i role="img" aria-hidden="true" className="sl-icon fal fa-copy fa-fw fa-sm" />
                    </div>
                  </button>
                </div>
              </pre>
              <p>
                Перед формаированием сигнатуры, ключи должны быть остортированы <strong>в алфовитном порядке</strong> и полученное тело <strong>сериализованно</strong>.
              </p>
              <ol>
                <li>Сортировка ключей в алфовитном порядке:</li>
              </ol>
              <pre tabIndex={0} className="sl-code-viewer sl-grid sl-inverted sl-overflow-x-hidden sl-overflow-y-hidden sl-relative sl-bg-canvas sl-outline-none sl-rounded-lg focus:sl-ring sl-ring-primary sl-ring-opacity-50 sl-group" role="group">
                <div className="sl-code-viewer__scroller sl-overflow-x-auto sl-overflow-y-auto" style={{ maxHeight: '500px' }}>
                  <div className="sl-code-highlight prism-code language-json" style={{ padding: '12px 15px', fontFamily: 'var(--font-code)', fontSize: 'var(--fs-code)', lineHeight: 'var(--lh-code)' }}>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          {'{'}
                        </span>
                        <span className="token plain" />
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain">{'  '}externalAccountId</span>
                        <span className="token operator" style={{ color: 'rgb(255, 123, 114)' }}>
                          :
                        </span>
                        <span className="token plain"> '</span>
                        <span className="token number" style={{ color: 'rgb(247, 140, 108)' }}>
                          111
                        </span>
                        <span className="token plain">'</span>
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          ,
                        </span>
                        <span className="token plain" />
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain">{'  '}gameId</span>
                        <span className="token operator" style={{ color: 'rgb(255, 123, 114)' }}>
                          :
                        </span>
                        <span className="token plain"> '</span>
                        <span className="token number" style={{ color: 'rgb(247, 140, 108)' }}>
                          2
                        </span>
                        <span className="token plain">'</span>
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          ,
                        </span>
                        <span className="token plain" />
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain">{'  '}orderId</span>
                        <span className="token operator" style={{ color: 'rgb(255, 123, 114)' }}>
                          :
                        </span>
                        <span className="token plain"> '</span>
                        <span className="token number" style={{ color: 'rgb(247, 140, 108)' }}>
                          7877
                        </span>
                        <span className="token number" style={{ color: 'rgb(247, 140, 108)' }}>
                          -453513
                        </span>
                        <span className="token number" style={{ color: 'rgb(247, 140, 108)' }}>
                          -8790
                        </span>
                        <span className="token plain">'</span>
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          ,
                        </span>
                        <span className="token plain" />
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain">{'  '}packageId</span>
                        <span className="token operator" style={{ color: 'rgb(255, 123, 114)' }}>
                          :
                        </span>
                        <span className="token plain"> '</span>
                        <span className="token number" style={{ color: 'rgb(247, 140, 108)' }}>
                          6
                        </span>
                        <span className="token plain">'</span>
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          ,
                        </span>
                        <span className="token plain" />
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain">{'  '}playerId</span>
                        <span className="token operator" style={{ color: 'rgb(255, 123, 114)' }}>
                          :
                        </span>
                        <span className="token plain"> 'gen_l'</span>
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          ,
                        </span>
                        <span className="token plain" />
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain">{'  '}serverId</span>
                        <span className="token operator" style={{ color: 'rgb(255, 123, 114)' }}>
                          :
                        </span>
                        <span className="token plain"> 'Europe'</span>
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain" />
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          {'}'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-testid="copy-button" className="sl-absolute sl-right-0 sl-pr-2 sl-invisible group-hover:sl-visible sl-invisible" style={{ top: '9px' }}>
                  <button type="button" className="sl-button sl-form-group-border sl-h-sm sl-text-base sl-font-medium sl-px-1.5 hover:sl-bg-canvas-50 active:sl-bg-canvas-100 sl-text-muted hover:sl-text-body focus:sl-text-body sl-rounded sl-border-transparent sl-border disabled:sl-opacity-70">
                    <div className="sl-mx-0">
                      <i role="img" aria-hidden="true" className="sl-icon fal fa-copy fa-fw fa-sm" />
                    </div>
                  </button>
                </div>
              </pre>
              <ol start={2}>
                <li>Сериализация остортированого тела:</li>
              </ol>
              <pre tabIndex={0} className="sl-code-viewer sl-grid sl-inverted sl-overflow-x-hidden sl-overflow-y-hidden sl-relative sl-bg-canvas sl-outline-none sl-rounded-lg focus:sl-ring sl-ring-primary sl-ring-opacity-50 sl-group" role="group">
                <div className="sl-code-viewer__scroller sl-overflow-x-auto sl-overflow-y-auto" style={{ maxHeight: '500px' }}>
                  <div className="sl-code-highlight prism-code language-json" style={{ padding: '12px 15px', fontFamily: 'var(--font-code)', fontSize: 'var(--fs-code)', lineHeight: 'var(--lh-code)' }}>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          {'{'}
                        </span>
                        <span className="token property" style={{ color: 'rgb(128, 203, 196)' }}>
                          "externalAccountId"
                        </span>
                        <span className="token operator" style={{ color: 'rgb(255, 123, 114)' }}>
                          :
                        </span>
                        <span className="token string" style={{ color: 'rgb(165, 214, 255)' }}>
                          "111"
                        </span>
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          ,
                        </span>
                        <span className="token property" style={{ color: 'rgb(128, 203, 196)' }}>
                          "gameId"
                        </span>
                        <span className="token operator" style={{ color: 'rgb(255, 123, 114)' }}>
                          :
                        </span>
                        <span className="token string" style={{ color: 'rgb(165, 214, 255)' }}>
                          "2"
                        </span>
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          ,
                        </span>
                        <span className="token property" style={{ color: 'rgb(128, 203, 196)' }}>
                          "orderId"
                        </span>
                        <span className="token operator" style={{ color: 'rgb(255, 123, 114)' }}>
                          :
                        </span>
                        <span className="token string" style={{ color: 'rgb(165, 214, 255)' }}>
                          "7877-453513-8790"
                        </span>
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          ,
                        </span>
                        <span className="token property" style={{ color: 'rgb(128, 203, 196)' }}>
                          "packageId"
                        </span>
                        <span className="token operator" style={{ color: 'rgb(255, 123, 114)' }}>
                          :
                        </span>
                        <span className="token string" style={{ color: 'rgb(165, 214, 255)' }}>
                          "6"
                        </span>
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          ,
                        </span>
                        <span className="token property" style={{ color: 'rgb(128, 203, 196)' }}>
                          "playerId"
                        </span>
                        <span className="token operator" style={{ color: 'rgb(255, 123, 114)' }}>
                          :
                        </span>
                        <span className="token string" style={{ color: 'rgb(165, 214, 255)' }}>
                          "gen_l"
                        </span>
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          ,
                        </span>
                        <span className="token property" style={{ color: 'rgb(128, 203, 196)' }}>
                          "serverId"
                        </span>
                        <span className="token operator" style={{ color: 'rgb(255, 123, 114)' }}>
                          :
                        </span>
                        <span className="token string" style={{ color: 'rgb(165, 214, 255)' }}>
                          "Europe"
                        </span>
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          {'}'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-testid="copy-button" className="sl-absolute sl-right-0 sl-pr-2 sl-invisible group-hover:sl-visible sl-invisible" style={{ top: '9px' }}>
                  <button type="button" className="sl-button sl-form-group-border sl-h-sm sl-text-base sl-font-medium sl-px-1.5 hover:sl-bg-canvas-50 active:sl-bg-canvas-100 sl-text-muted hover:sl-text-body focus:sl-text-body sl-rounded sl-border-transparent sl-border disabled:sl-opacity-70">
                    <div className="sl-mx-0">
                      <i role="img" aria-hidden="true" className="sl-icon fal fa-copy fa-fw fa-sm" />
                    </div>
                  </button>
                </div>
              </pre>
              <p>
                Из сериализованного тела и приватного ключа, формируется сигнатура с помощью алгоритмом{' '}
                <code className="sl-font-mono sl-font-medium sl-mx-0.5 sl-px-1 sl-py-0.5 sl-bg-code sl-text-on-code sl-rounded sl-border" style={{ fontSize: '0.8125em' }}>
                  SHA256
                </code>
                .
                <br />
                Пример кода на{' '}
                <code className="sl-font-mono sl-font-medium sl-mx-0.5 sl-px-1 sl-py-0.5 sl-bg-code sl-text-on-code sl-rounded sl-border" style={{ fontSize: '0.8125em' }}>
                  Node.js
                </code>
              </p>
              <pre tabIndex={0} className="sl-code-viewer sl-grid sl-inverted sl-overflow-x-hidden sl-overflow-y-hidden sl-relative sl-bg-canvas sl-outline-none sl-rounded-lg focus:sl-ring sl-ring-primary sl-ring-opacity-50 sl-group" role="group">
                <div className="sl-code-viewer__scroller sl-overflow-x-auto sl-overflow-y-auto" style={{ maxHeight: '500px' }}>
                  <div className="sl-code-highlight prism-code language-json" style={{ padding: '12px 15px', fontFamily: 'var(--font-code)', fontSize: 'var(--fs-code)', lineHeight: 'var(--lh-code)' }}>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain">const crypto = require('crypto');</span>
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain" style={{ display: 'inline-block' }}>
                          {'\n'}
                        </span>
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain" style={{ display: 'inline-block' }}>
                          {'\n'}
                        </span>
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain"> const signature = req.headers</span>
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          [
                        </span>
                        <span className="token plain">'signature'</span>
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          ]
                        </span>
                        <span className="token plain">; </span>
                        <span className="token comment" style={{ color: 'rgb(139, 148, 158)' }}>
                          // Берем сигнатуру из заголовка запроса
                        </span>
                        <span className="token plain" />
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain" style={{ display: 'inline-block' }}>
                          {'\n'}
                        </span>
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain"> const data = req.body; </span>
                        <span className="token comment" style={{ color: 'rgb(139, 148, 158)' }}>
                          // Берем тело запроса
                        </span>
                        <span className="token plain" />
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain" style={{ display: 'inline-block' }}>
                          {'\n'}
                        </span>
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain"> const privateKey = process.env.PRIVATE_KEY; </span>
                        <span className="token comment" style={{ color: 'rgb(139, 148, 158)' }}>
                          // Берем приватный ключ
                        </span>
                        <span className="token plain" />
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain" style={{ display: 'inline-block' }}>
                          {'\n'}
                        </span>
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain"> const sortData = Object.keys(data) </span>
                        <span className="token comment" style={{ color: 'rgb(139, 148, 158)' }}>
                          // Сортируем ключи по алфовиту
                        </span>
                        <span className="token plain" />
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain">{'   '}.sort()</span>
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain">{'   '}.reduce((obj</span>
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          ,
                        </span>
                        <span className="token plain"> key) =&gt; </span>
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          {'{'}
                        </span>
                        <span className="token plain" />
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain">{'     '}obj</span>
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          [
                        </span>
                        <span className="token plain">key</span>
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          ]
                        </span>
                        <span className="token plain"> = data</span>
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          [
                        </span>
                        <span className="token plain">key</span>
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          ]
                        </span>
                        <span className="token plain">;</span>
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain">{'     '}return obj;</span>
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain">{'   '}</span>
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          {'}'}
                        </span>
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          ,
                        </span>
                        <span className="token plain"> </span>
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          {'{'}
                        </span>
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          {'}'}
                        </span>
                        <span className="token plain">);</span>
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain" style={{ display: 'inline-block' }}>
                          {'\n'}
                        </span>
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain"> const payloadResultStringify = JSON.stringify(sortData); </span>
                        <span className="token comment" style={{ color: 'rgb(139, 148, 158)' }}>
                          // Сериализуем тело
                        </span>
                        <span className="token plain" />
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain" style={{ display: 'inline-block' }}>
                          {'\n'}
                        </span>
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain"> const signatureCheck = crypto.createHmac('sha256'</span>
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          ,
                        </span>
                        <span className="token plain"> privateKey).update(payloadResultStringify).digest('hex'); </span>
                        <span className="token comment" style={{ color: 'rgb(139, 148, 158)' }}>
                          // Создаем сигнатуру для проверки
                        </span>
                        <span className="token plain" />
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain"> </span>
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain"> if (signature === signatureCheck) </span>
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          {'{'}
                        </span>
                        <span className="token plain" />
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain">{'  '}</span>
                        <span className="token comment" style={{ color: 'rgb(139, 148, 158)' }}>
                          //Сигнатуры совпадают. Создаем заказ на основе данных который пришли{' '}
                        </span>
                        <span className="token plain" />
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain"> </span>
                        <span className="token punctuation" style={{ color: 'inherit' }}>
                          {'}'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-testid="copy-button" className="sl-absolute sl-right-0 sl-pr-2 sl-invisible group-hover:sl-visible sl-invisible" style={{ top: '9px' }}>
                  <button type="button" className="sl-button sl-form-group-border sl-h-sm sl-text-base sl-font-medium sl-px-1.5 hover:sl-bg-canvas-50 active:sl-bg-canvas-100 sl-text-muted hover:sl-text-body focus:sl-text-body sl-rounded sl-border-transparent sl-border disabled:sl-opacity-70">
                    <div className="sl-mx-0">
                      <i role="img" aria-hidden="true" className="sl-icon fal fa-copy fa-fw fa-sm" />
                    </div>
                  </button>
                </div>
              </pre>
              <h3 id="создание-сигнатуры-для-остальных-запросов" className="sl-link-heading sl-text-2xl sl-leading-snug sl-font-prose sl-font-semibold sl-text-heading">
                <a href="#создание-сигнатуры-для-остальных-запросов" className="sl-link sl-link-heading__link sl-inline-flex sl-items-center sl-text-current">
                  <div>Создание сигнатуры для остальных запросов</div>
                  <div className="sl-link-heading__icon sl-text-base sl-ml-4 sl-text-muted">
                    <i role="img" aria-hidden="true" className="sl-icon fal fa-link" />
                  </div>
                </a>
              </h3>
              <p>Каждый запрос должен содержит заголовок с сигнатурой</p>
              <pre tabIndex={0} className="sl-code-viewer sl-grid sl-inverted sl-overflow-x-hidden sl-overflow-y-hidden sl-relative sl-bg-canvas sl-outline-none sl-rounded-lg focus:sl-ring sl-ring-primary sl-ring-opacity-50 sl-group" role="group">
                <div className="sl-code-viewer__scroller sl-overflow-x-auto sl-overflow-y-auto" style={{ maxHeight: '500px' }}>
                  <div className="sl-code-highlight prism-code language-json" style={{ padding: '12px 15px', fontFamily: 'var(--font-code)', fontSize: 'var(--fs-code)', lineHeight: 'var(--lh-code)' }}>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain">Signature</span>
                        <span className="token operator" style={{ color: 'rgb(255, 123, 114)' }}>
                          :
                        </span>
                        <span className="token plain"> 69572a9500f04734b3655e6973c393bb9861dcd55c7e8e1985be122c102254d6</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-testid="copy-button" className="sl-absolute sl-right-0 sl-pr-2 sl-invisible group-hover:sl-visible sl-invisible" style={{ top: '9px' }}>
                  <button type="button" className="sl-button sl-form-group-border sl-h-sm sl-text-base sl-font-medium sl-px-1.5 hover:sl-bg-canvas-50 active:sl-bg-canvas-100 sl-text-muted hover:sl-text-body focus:sl-text-body sl-rounded sl-border-transparent sl-border disabled:sl-opacity-70">
                    <div className="sl-mx-0">
                      <i role="img" aria-hidden="true" className="sl-icon fal fa-copy fa-fw fa-sm" />
                    </div>
                  </button>
                </div>
              </pre>
              <p>
                Сигнатура формируется из строки зашифрованой алгоритмом{' '}
                <code className="sl-font-mono sl-font-medium sl-mx-0.5 sl-px-1 sl-py-0.5 sl-bg-code sl-text-on-code sl-rounded sl-border" style={{ fontSize: '0.8125em' }}>
                  SHA256
                </code>
                . Строка формируется следующим образом:
              </p>
              <pre tabIndex={0} className="sl-code-viewer sl-grid sl-inverted sl-overflow-x-hidden sl-overflow-y-hidden sl-relative sl-bg-canvas sl-outline-none sl-rounded-lg focus:sl-ring sl-ring-primary sl-ring-opacity-50 sl-group" role="group">
                <div className="sl-code-viewer__scroller sl-overflow-x-auto sl-overflow-y-auto" style={{ maxHeight: '500px' }}>
                  <div className="sl-code-highlight prism-code language-json" style={{ padding: '12px 15px', fontFamily: 'var(--font-code)', fontSize: 'var(--fs-code)', lineHeight: 'var(--lh-code)' }}>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain">IDмагазина</span>
                        <span className="token operator" style={{ color: 'rgb(255, 123, 114)' }}>
                          :
                        </span>
                        <span className="token plain">Приватныйключ</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-testid="copy-button" className="sl-absolute sl-right-0 sl-pr-2 sl-invisible group-hover:sl-visible sl-invisible" style={{ top: '9px' }}>
                  <button type="button" className="sl-button sl-form-group-border sl-h-sm sl-text-base sl-font-medium sl-px-1.5 hover:sl-bg-canvas-50 active:sl-bg-canvas-100 sl-text-muted hover:sl-text-body focus:sl-text-body sl-rounded sl-border-transparent sl-border disabled:sl-opacity-70">
                    <div className="sl-mx-0">
                      <i role="img" aria-hidden="true" className="sl-icon fal fa-copy fa-fw fa-sm" />
                    </div>
                  </button>
                </div>
              </pre>
              <p>Исходя из данных в примере выше, получается строка:</p>
              <pre tabIndex={0} className="sl-code-viewer sl-grid sl-inverted sl-overflow-x-hidden sl-overflow-y-hidden sl-relative sl-bg-canvas sl-outline-none sl-rounded-lg focus:sl-ring sl-ring-primary sl-ring-opacity-50 sl-group" role="group">
                <div className="sl-code-viewer__scroller sl-overflow-x-auto sl-overflow-y-auto" style={{ maxHeight: '500px' }}>
                  <div className="sl-code-highlight prism-code language-json" style={{ padding: '12px 15px', fontFamily: 'var(--font-code)', fontSize: 'var(--fs-code)', lineHeight: 'var(--lh-code)' }}>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token number" style={{ color: 'rgb(247, 140, 108)' }}>
                          6943469552
                        </span>
                        <span className="token operator" style={{ color: 'rgb(255, 123, 114)' }}>
                          :
                        </span>
                        <span className="token plain">je1c0xy2R7wY7dPzYB5ikklKofTVBhQP3EmvjHAWaia3fRn3c2</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-testid="copy-button" className="sl-absolute sl-right-0 sl-pr-2 sl-invisible group-hover:sl-visible sl-invisible" style={{ top: '9px' }}>
                  <button type="button" className="sl-button sl-form-group-border sl-h-sm sl-text-base sl-font-medium sl-px-1.5 hover:sl-bg-canvas-50 active:sl-bg-canvas-100 sl-text-muted hover:sl-text-body focus:sl-text-body sl-rounded sl-border-transparent sl-border disabled:sl-opacity-70">
                    <div className="sl-mx-0">
                      <i role="img" aria-hidden="true" className="sl-icon fal fa-copy fa-fw fa-sm" />
                    </div>
                  </button>
                </div>
              </pre>
              <p>
                Чтобы создать сигнатуру,вам нужно зашифровать строку приведенную выше алгоритмом{' '}
                <code className="sl-font-mono sl-font-medium sl-mx-0.5 sl-px-1 sl-py-0.5 sl-bg-code sl-text-on-code sl-rounded sl-border" style={{ fontSize: '0.8125em' }}>
                  SHA256
                </code>
                .
                <br /> Пример кода на{' '}
                <code className="sl-font-mono sl-font-medium sl-mx-0.5 sl-px-1 sl-py-0.5 sl-bg-code sl-text-on-code sl-rounded sl-border" style={{ fontSize: '0.8125em' }}>
                  Node.js
                </code>
                :
              </p>
              <pre tabIndex={0} className="sl-code-viewer sl-grid sl-inverted sl-overflow-x-hidden sl-overflow-y-hidden sl-relative sl-bg-canvas sl-outline-none sl-rounded-lg focus:sl-ring sl-ring-primary sl-ring-opacity-50 sl-group" role="group">
                <div className="sl-code-viewer__scroller sl-overflow-x-auto sl-overflow-y-auto" style={{ maxHeight: '500px' }}>
                  <div className="sl-code-highlight prism-code language-json" style={{ padding: '12px 15px', fontFamily: 'var(--font-code)', fontSize: 'var(--fs-code)', lineHeight: 'var(--lh-code)' }}>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain">const crypto = require('crypto');</span>
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain" style={{ display: 'inline-block' }}>
                          {'\n'}
                        </span>
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain">const signature = crypto.createHmac('sha256').update('</span>
                        <span className="token number" style={{ color: 'rgb(247, 140, 108)' }}>
                          6943469552
                        </span>
                        <span className="token operator" style={{ color: 'rgb(255, 123, 114)' }}>
                          :
                        </span>
                        <span className="token plain">je1c0xy2R7wY7dPzYB5ikklKofTVBhQP3EmvjHAWaia3fRn3c2').digest('hex');</span>
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain" style={{ display: 'inline-block' }}>
                          {'\n'}
                        </span>
                      </div>
                    </div>
                    <div className="sl-flex">
                      <div className="sl-flex-1 sl-break-all">
                        <span className="token plain">console.log(signature); </span>
                        <span className="token comment" style={{ color: 'rgb(139, 148, 158)' }}>
                          //69572a9500f04734b3655e6973c393bb9861dcd55c7e8e1985be122c102254d6
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-testid="copy-button" className="sl-absolute sl-right-0 sl-pr-2 sl-invisible group-hover:sl-visible sl-invisible" style={{ top: '9px' }}>
                  <button type="button" className="sl-button sl-form-group-border sl-h-sm sl-text-base sl-font-medium sl-px-1.5 hover:sl-bg-canvas-50 active:sl-bg-canvas-100 sl-text-muted hover:sl-text-body focus:sl-text-body sl-rounded sl-border-transparent sl-border disabled:sl-opacity-70">
                    <div className="sl-mx-0">
                      <i role="img" aria-hidden="true" className="sl-icon fal fa-copy fa-fw fa-sm" />
                    </div>
                  </button>
                </div>
              </pre>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StartPage;
