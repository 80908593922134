import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
const TwoStepPage = () => {
  return (
    <>
      <div data-test="project-page" className="sl-w-full sl-mx-auto sl-px-20 sl-pt-20 sl-pb-8" style={{ maxWidth: '1500px' }}>
        <div className="sl-stack sl-stack--vertical sl-stack--8 HttpOperation sl-flex sl-flex-col sl-items-stretch sl-w-full">
          <div className="sl-stack sl-stack--vertical sl-stack--5 sl-flex sl-flex-col sl-items-stretch">
            <div className="sl-relative">
              <div className="sl-stack sl-stack--horizontal sl-stack--5 sl-flex sl-flex-row sl-items-center">
                <h1 className="sl-text-5xl sl-leading-tight sl-font-prose sl-font-semibold sl-text-heading">2. Отправка ваших статусов</h1>
                <div className="sl-stack sl-stack--horizontal sl-stack--2 sl-flex sl-flex-row sl-items-center" />
              </div>
            </div>
            <div className="sl-relative">
              <div>
                <div title="https://your.domain.com/api/status-list" className="sl-stack sl-stack--horizontal sl-stack--3 sl-inline-flex sl-flex-row sl-items-center sl-max-w-full sl-font-mono sl-py-2 sl-pr-4 sl-pl-2.5 sl-bg-canvas-50 sl-rounded-lg">
                  <div className="sl-text-lg sl-font-semibold sl-uppercase sl-px-2.5 sl-py-1 sl-bg-success sl-text-on-primary sl-rounded-lg">get</div>
                  <div className="sl-flex sl-overflow-x-hidden sl-text-lg sl-select-all">
                    <div dir="rtl" className="sl-overflow-x-hidden sl-truncate sl-text-muted">
                      <span dir="ltr" style={{ unicodeBidi: 'bidi-override' }}>
                        https://your.domain.com/api
                      </span>
                    </div>
                    <div className="sl-flex-1 sl-font-semibold">/status-list</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sl-flex">
            <div data-testid="two-column-left" className="sl-flex-1 sl-w-0">
              <div className="sl-stack sl-stack--vertical sl-stack--10 sl-flex sl-flex-col sl-items-stretch">
                <div className="sl-relative">
                  <div className="sl-prose sl-markdown-viewer HttpOperation__Description">
                    <p>
                      Создайте этот эндпоинт, чтобы мы смогли сопоставить свои статусы с вашими. Это нужно будет при обновлении статусов заказа в нашей системе. На наш один статус, вы можете ставить своих несколько.
                      <br />
                      <br />
                      Пример:
                      <br />
                      Отправка доната у вас может быть в несколько этапов:{' '}
                      <code className="sl-font-mono sl-font-medium sl-mx-0.5 sl-px-1 sl-py-0.5 sl-bg-code sl-text-on-code sl-rounded sl-border" style={{ fontSize: '0.8125em' }}>
                        sending
                      </code>
                      ,{' '}
                      <code className="sl-font-mono sl-font-medium sl-mx-0.5 sl-px-1 sl-py-0.5 sl-bg-code sl-text-on-code sl-rounded sl-border" style={{ fontSize: '0.8125em' }}>
                        in-progress
                      </code>{' '}
                      и т.д
                      <br />
                      Тогда в наш статус{' '}
                      <code className="sl-font-mono sl-font-medium sl-mx-0.5 sl-px-1 sl-py-0.5 sl-bg-code sl-text-on-code sl-rounded sl-border" style={{ fontSize: '0.8125em' }}>
                        PROCESSING
                      </code>{' '}
                      надо указать все эти статусы в виде массива значений.
                    </p>
                    <pre tabIndex={0} className="sl-code-viewer sl-grid sl-inverted sl-overflow-x-hidden sl-overflow-y-hidden sl-relative sl-bg-canvas sl-outline-none sl-rounded-lg focus:sl-ring sl-ring-primary sl-ring-opacity-50 sl-group" role="group">
                      <div className="sl-code-viewer__scroller sl-overflow-x-auto sl-overflow-y-auto" style={{ maxHeight: '500px' }}>
                        <div className="sl-code-highlight prism-code language-json" style={{ padding: '12px 15px', fontFamily: 'var(--font-code)', fontSize: 'var(--fs-code)', lineHeight: 'var(--lh-code)' }}>
                          <div className="sl-flex">
                            <div className="sl-flex-1 sl-break-all">
                              <span className="token plain">PROCESSING</span>
                              <span className="token operator" style={{ color: 'rgb(255, 123, 114)' }}>
                                :
                              </span>
                              <span className="token plain"> </span>
                              <span className="token punctuation" style={{ color: 'inherit' }}>
                                [
                              </span>
                              <span className="token plain">'sending'</span>
                              <span className="token punctuation" style={{ color: 'inherit' }}>
                                ,
                              </span>
                              <span className="token plain">'in-progress'</span>
                              <span className="token punctuation" style={{ color: 'inherit' }}>
                                ]
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div data-testid="copy-button" className="sl-absolute sl-right-0 sl-pr-2 sl-invisible group-hover:sl-visible sl-invisible" style={{ top: '9px' }}>
                        <button type="button" className="sl-button sl-form-group-border sl-h-sm sl-text-base sl-font-medium sl-px-1.5 hover:sl-bg-canvas-50 active:sl-bg-canvas-100 sl-text-muted hover:sl-text-body focus:sl-text-body sl-rounded sl-border-transparent sl-border disabled:sl-opacity-70">
                          <div className="sl-mx-0">
                            <i role="img" aria-hidden="true" className="sl-icon fal fa-copy fa-fw fa-sm" />
                          </div>
                        </button>
                      </div>
                    </pre>
                    <p>
                      Также это действует и для статусов{' '}
                      <code className="sl-font-mono sl-font-medium sl-mx-0.5 sl-px-1 sl-py-0.5 sl-bg-code sl-text-on-code sl-rounded sl-border" style={{ fontSize: '0.8125em' }}>
                        ERROR
                      </code>{' '}
                      и{' '}
                      <code className="sl-font-mono sl-font-medium sl-mx-0.5 sl-px-1 sl-py-0.5 sl-bg-code sl-text-on-code sl-rounded sl-border" style={{ fontSize: '0.8125em' }}>
                        COMPLETED
                      </code>
                      Если статус у вас один, то ставить надо массив с одни значением
                    </p>
                    <pre tabIndex={0} className="sl-code-viewer sl-grid sl-inverted sl-overflow-x-hidden sl-overflow-y-hidden sl-relative sl-bg-canvas sl-outline-none sl-rounded-lg focus:sl-ring sl-ring-primary sl-ring-opacity-50 sl-group" role="group">
                      <div className="sl-code-viewer__scroller sl-overflow-x-auto sl-overflow-y-auto" style={{ maxHeight: '500px' }}>
                        <div className="sl-code-highlight prism-code language-json" style={{ padding: '12px 15px', fontFamily: 'var(--font-code)', fontSize: 'var(--fs-code)', lineHeight: 'var(--lh-code)' }}>
                          <div className="sl-flex">
                            <div className="sl-flex-1 sl-break-all">
                              <span className="token plain">PROCESSING</span>
                              <span className="token operator" style={{ color: 'rgb(255, 123, 114)' }}>
                                :
                              </span>
                              <span className="token plain"> </span>
                              <span className="token punctuation" style={{ color: 'inherit' }}>
                                [
                              </span>
                              <span className="token plain">'processing'</span>
                              <span className="token punctuation" style={{ color: 'inherit' }}>
                                ]
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div data-testid="copy-button" className="sl-absolute sl-right-0 sl-pr-2 sl-invisible group-hover:sl-visible sl-invisible" style={{ top: '9px' }}>
                        <button type="button" className="sl-button sl-form-group-border sl-h-sm sl-text-base sl-font-medium sl-px-1.5 hover:sl-bg-canvas-50 active:sl-bg-canvas-100 sl-text-muted hover:sl-text-body focus:sl-text-body sl-rounded sl-border-transparent sl-border disabled:sl-opacity-70">
                          <div className="sl-mx-0">
                            <i role="img" aria-hidden="true" className="sl-icon fal fa-copy fa-fw fa-sm" />
                          </div>
                        </button>
                      </div>
                    </pre>
                  </div>
                </div>
                <div className="sl-stack sl-stack--vertical sl-stack--8 sl-flex sl-flex-col sl-items-stretch">
                  <div className="sl-flex sl-w-full">
                    <h2 id="Request" aria-label="Request" className="sl-link-heading sl-py-1 sl-pr-6 sl-text-4xl sl-leading-tight sl-font-prose sl-font-bold sl-text-heading">
                      <a href="#Request" className="sl-link sl-link-heading__link sl-inline-flex sl-items-center sl-text-current">
                        <div>Request</div>
                        <div className="sl-link-heading__icon sl-text-base sl-ml-4 sl-text-muted">
                          <i role="img" aria-hidden="true" className="sl-icon fal fa-link" />
                        </div>
                      </a>
                    </h2>
                    <div className="sl-flex sl-flex-grow sl-self-center sl-py-1" style={{ minWidth: '0px' }} />
                  </div>
                  <div className="sl-stack sl-stack--vertical sl-stack--5 sl-flex sl-flex-col sl-items-stretch">
                    <div className="sl-flex sl-w-full">
                      <h3 id="request-headers" aria-label="Headers" className="sl-link-heading sl-py-1 sl-pr-6 sl-text-2xl sl-leading-snug sl-font-prose sl-font-semibold sl-text-heading">
                        <a href="#request-headers" className="sl-link sl-link-heading__link sl-inline-flex sl-items-center sl-text-current">
                          <div>Headers</div>
                          <div className="sl-link-heading__icon sl-text-base sl-ml-4 sl-text-muted">
                            <i role="img" aria-hidden="true" className="sl-icon fal fa-link" />
                          </div>
                        </a>
                      </h3>
                      <div className="sl-flex sl-flex-grow sl-self-center sl-py-1" style={{ minWidth: '0px' }} />
                    </div>
                    <div className id="mosaic-provider-react-aria-1-1">
                      <div data-overlay-container="true" className>
                        <div className="JsonSchemaViewer">
                          <div />
                          <div data-level={0} className="sl-text-sm">
                            <div data-id="b8171c162e205" data-test="schema-row" className="sl-flex sl-relative sl-max-w-full sl-py-2">
                              <div className="sl-stack sl-stack--vertical sl-stack--1 sl-flex sl-flex-1 sl-flex-col sl-items-stretch sl-max-w-full">
                                <div className="sl-flex sl-items-center sl-max-w-full">
                                  <div className="sl-flex sl-items-baseline sl-text-base">
                                    <div data-test="property-name-signature" className="sl-font-mono sl-font-semibold sl-mr-2">
                                      signature
                                    </div>
                                    <span data-test="property-type" className="sl-truncate sl-text-muted">
                                      string
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sl-stack sl-stack--vertical sl-stack--1 sl-flex sl-flex-col sl-w-full sl-h-auto">
                  <div className="sl-flex sl-w-full">
                    <h2 id="Responses" aria-label="Responses" className="sl-link-heading sl-py-1 sl-pr-6 sl-text-4xl sl-leading-tight sl-font-prose sl-font-bold sl-text-heading">
                      <a href="#Responses" className="sl-link sl-link-heading__link sl-inline-flex sl-items-center sl-text-current">
                        <div>Responses</div>
                        <div className="sl-link-heading__icon sl-text-base sl-ml-4 sl-text-muted">
                          <i role="img" aria-hidden="true" className="sl-icon fal fa-link" />
                        </div>
                      </a>
                    </h2>
                    <div className="sl-flex sl-flex-grow sl-self-center sl-py-1" style={{ minWidth: '0px' }}>
                      <div id="react-aria-3-31" aria-orientation="horizontal" className="sl-stack sl-stack--horizontal sl-stack--2 sl-flex sl-overflow-x-auto sl-overflow-y-hidden sl-flex-row sl-h-auto sl-text-lg" role="tablist">
                        <div tabIndex={0} data-key={200} id="react-aria-3-31-tab-200" aria-selected="true" aria-controls="react-aria-3-31-tabpanel-200" className="sl-font-medium sl-p-1 sl-px-2 sl-py-1 sl-bg-success sl-text-on-primary sl-rounded-lg sl-border-light sl-cursor" role="tab">
                          200
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="react-aria-1-11-tabpanel-200" aria-labelledby="react-aria-3-31-tab-200" className="sl-flex-1 sl-py-0" role="tabpanel">
                    <div className="sl-stack sl-stack--vertical sl-stack--8 sl-flex sl-flex-col sl-items-stretch sl-pt-8">
                      <div className="sl-flex sl-w-full">
                        <h3 id="response-body" aria-label="Body" className="sl-link-heading sl-py-1 sl-pr-6 sl-text-2xl sl-leading-snug sl-font-prose sl-font-semibold sl-text-heading">
                          <a href="#response-body" className="sl-link sl-link-heading__link sl-inline-flex sl-items-center sl-text-current">
                            <div>Body</div>
                            <div className="sl-link-heading__icon sl-text-base sl-ml-4 sl-text-muted">
                              <i role="img" aria-hidden="true" className="sl-icon fal fa-link" />
                            </div>
                          </a>
                        </h3>
                        <div className="sl-flex sl-flex-grow sl-self-center sl-py-1" style={{ minWidth: '0px' }}>
                          <div className="sl-flex sl-flex-1 sl-justify-end">
                            <div className="sl-select sl-relative">
                              <div aria-hidden="true" style={{ border: '0px', clip: 'rect(0px, 0px, 0px, 0px)', clipPath: 'inset(50%)', height: '1px', margin: '0px -1px -1px 0px', overflow: 'hidden', padding: '0px', position: 'absolute', width: '1px', whiteSpace: 'nowrap' }}>
                                <input type="text" tabIndex={0} style={{ fontSize: '16px' }} />
                                <label>
                                  <select size={1} tabIndex={-1}>
                                    <option />
                                    <option value={0}>application/json</option>
                                  </select>
                                </label>
                              </div>
                              <div className="sl-relative">
                                <button
                                  aria-label="Response Body Content Type"
                                  aria-haspopup="listbox"
                                  aria-expanded="false"
                                  id="react-aria-3-34"
                                  aria-labelledby="react-aria-3-34 react-aria-3-36"
                                  type="button"
                                  className="sl-button sl-form-group-border sl-w-full sl-h-sm sl-text-base sl-font-normal sl-px-1.5 sl-bg-transparent sl-rounded sl-border-transparent hover:sl-border-input focus:sl-border-primary active:sl-border-primary sl-border disabled:sl-opacity-60">
                                  <div className="sl-flex sl-flex-1 sl-justify-items-start sl-items-center">
                                    <div className="sl-pr-1">application/json</div>
                                  </div>
                                  <div className="sl-text-xs sl--mr-0.5 sl-ml-1">
                                    <div className="sl-pt-0.5 sl-pr-0.5">
                                      <i role="img" aria-hidden="true" className="sl-icon fal fa-chevron-down fa-xs" />
                                    </div>
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className id="mosaic-provider-react-aria-4-1">
                        <div data-overlay-container="true" className>
                          <div className="JsonSchemaViewer">
                            <div />
                            <div data-level={0} className="sl-text-sm sl-ml-px sl-border-l">
                              <div data-id="v7t6bmjo4zxl2" data-test="schema-row" className="sl-flex sl-relative sl-max-w-full sl-py-2 sl-pl-3">
                                <div className="sl-w-3 sl-mt-2 sl-mr-3 sl--ml-3 sl-border-t" />
                                <div className="sl-stack sl-stack--vertical sl-stack--1 sl-flex sl-flex-1 sl-flex-col sl-items-stretch sl-max-w-full">
                                  <div className="sl-flex sl-items-center sl-max-w-full">
                                    <div className="sl-flex sl-items-baseline sl-text-base">
                                      <div data-test="property-name-PROCESSING" className="sl-font-mono sl-font-semibold sl-mr-2">
                                        PROCESSING
                                      </div>
                                      <div className="sl-select sl-relative">
                                        <div aria-hidden="true" style={{ border: '0px', clip: 'rect(0px, 0px, 0px, 0px)', clipPath: 'inset(50%)', height: '1px', margin: '0px -1px -1px 0px', overflow: 'hidden', padding: '0px', position: 'absolute', width: '1px', whiteSpace: 'nowrap' }}>
                                          <input type="text" tabIndex={0} style={{ fontSize: '16px' }} />
                                          <label>
                                            <select size={2} tabIndex={-1}>
                                              <option />
                                              <option value={0}>array (oneOf) [strings]</option>
                                              <option value={1}>array (oneOf) [integers]</option>
                                            </select>
                                          </label>
                                        </div>
                                        <div className="sl-relative">
                                          <button
                                            aria-label="Pick a type"
                                            aria-haspopup="listbox"
                                            aria-expanded="false"
                                            id="react-aria-4-4"
                                            aria-labelledby="react-aria-4-4 react-aria-4-6"
                                            type="button"
                                            className="sl-button sl-form-group-border sl-w-full sl-h-sm sl-text-base sl-font-normal sl-px-1.5 sl-bg-transparent sl-rounded sl-border-transparent hover:sl-border-input focus:sl-border-primary active:sl-border-primary sl-border disabled:sl-opacity-60">
                                            <div className="sl-flex sl-flex-1 sl-justify-items-start sl-items-center">
                                              <div className="sl-pr-1">array (oneOf) [strings]</div>
                                            </div>
                                            <div className="sl-text-xs sl--mr-0.5 sl-ml-1">
                                              <div className="sl-pt-0.5 sl-pr-0.5">
                                                <i role="img" aria-hidden="true" className="sl-icon fal fa-chevron-down fa-xs" />
                                              </div>
                                            </div>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div data-id="b92y9w4u60eol" data-test="schema-row" className="sl-flex sl-relative sl-max-w-full sl-py-2 sl-pl-3">
                                <div className="sl-w-3 sl-mt-2 sl-mr-3 sl--ml-3 sl-border-t" />
                                <div className="sl-stack sl-stack--vertical sl-stack--1 sl-flex sl-flex-1 sl-flex-col sl-items-stretch sl-max-w-full">
                                  <div className="sl-flex sl-items-center sl-max-w-full">
                                    <div className="sl-flex sl-items-baseline sl-text-base">
                                      <div data-test="property-name-COMPLETED" className="sl-font-mono sl-font-semibold sl-mr-2">
                                        COMPLETED
                                      </div>
                                      <div className="sl-select sl-relative">
                                        <div aria-hidden="true" style={{ border: '0px', clip: 'rect(0px, 0px, 0px, 0px)', clipPath: 'inset(50%)', height: '1px', margin: '0px -1px -1px 0px', overflow: 'hidden', padding: '0px', position: 'absolute', width: '1px', whiteSpace: 'nowrap' }}>
                                          <input type="text" tabIndex={0} style={{ fontSize: '16px' }} />
                                          <label>
                                            <select size={2} tabIndex={-1}>
                                              <option />
                                              <option value={0}>array (oneOf) [strings]</option>
                                              <option value={1}>array (oneOf) [integers]</option>
                                            </select>
                                          </label>
                                        </div>
                                        <div className="sl-relative">
                                          <button
                                            aria-label="Pick a type"
                                            aria-haspopup="listbox"
                                            aria-expanded="false"
                                            id="react-aria-4-10"
                                            aria-labelledby="react-aria-4-10 react-aria-4-12"
                                            type="button"
                                            className="sl-button sl-form-group-border sl-w-full sl-h-sm sl-text-base sl-font-normal sl-px-1.5 sl-bg-transparent sl-rounded sl-border-transparent hover:sl-border-input focus:sl-border-primary active:sl-border-primary sl-border disabled:sl-opacity-60">
                                            <div className="sl-flex sl-flex-1 sl-justify-items-start sl-items-center">
                                              <div className="sl-pr-1">array (oneOf) [strings]</div>
                                            </div>
                                            <div className="sl-text-xs sl--mr-0.5 sl-ml-1">
                                              <div className="sl-pt-0.5 sl-pr-0.5">
                                                <i role="img" aria-hidden="true" className="sl-icon fal fa-chevron-down fa-xs" />
                                              </div>
                                            </div>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div data-id="m7dn4jqjzdnsg" data-test="schema-row" className="sl-flex sl-relative sl-max-w-full sl-py-2 sl-pl-3">
                                <div className="sl-w-3 sl-mt-2 sl-mr-3 sl--ml-3 sl-border-t" />
                                <div className="sl-stack sl-stack--vertical sl-stack--1 sl-flex sl-flex-1 sl-flex-col sl-items-stretch sl-max-w-full">
                                  <div className="sl-flex sl-items-center sl-max-w-full">
                                    <div className="sl-flex sl-items-baseline sl-text-base">
                                      <div data-test="property-name-ERROR" className="sl-font-mono sl-font-semibold sl-mr-2">
                                        ERROR
                                      </div>
                                      <div className="sl-select sl-relative">
                                        <div aria-hidden="true" style={{ border: '0px', clip: 'rect(0px, 0px, 0px, 0px)', clipPath: 'inset(50%)', height: '1px', margin: '0px -1px -1px 0px', overflow: 'hidden', padding: '0px', position: 'absolute', width: '1px', whiteSpace: 'nowrap' }}>
                                          <input type="text" tabIndex={0} style={{ fontSize: '16px' }} />
                                          <label>
                                            <select size={2} tabIndex={-1}>
                                              <option />
                                              <option value={0}>array (oneOf) [strings]</option>
                                              <option value={1}>array (oneOf) [integers]</option>
                                            </select>
                                          </label>
                                        </div>
                                        <div className="sl-relative">
                                          <button
                                            aria-label="Pick a type"
                                            aria-haspopup="listbox"
                                            aria-expanded="false"
                                            id="react-aria-4-16"
                                            aria-labelledby="react-aria-4-16 react-aria-4-18"
                                            type="button"
                                            className="sl-button sl-form-group-border sl-w-full sl-h-sm sl-text-base sl-font-normal sl-px-1.5 sl-bg-transparent sl-rounded sl-border-transparent hover:sl-border-input focus:sl-border-primary active:sl-border-primary sl-border disabled:sl-opacity-60">
                                            <div className="sl-flex sl-flex-1 sl-justify-items-start sl-items-center">
                                              <div className="sl-pr-1">array (oneOf) [strings]</div>
                                            </div>
                                            <div className="sl-text-xs sl--mr-0.5 sl-ml-1">
                                              <div className="sl-pt-0.5 sl-pr-0.5">
                                                <i role="img" aria-hidden="true" className="sl-icon fal fa-chevron-down fa-xs" />
                                              </div>
                                            </div>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div data-testid="two-column-right" className="sl-relative sl-w-2/5 sl-ml-16" style={{ maxWidth: '500px' }}>
              <div className="sl-stack sl-stack--vertical sl-stack--6 sl-flex sl-flex-col sl-items-stretch">
                <div className="sl-inverted">
                  <div className="sl-overflow-y-hidden sl-rounded-lg">
                    <div className="TryItPanel sl-bg-canvas-100 sl-rounded-lg">
                      <div className="sl-panel sl-outline-none sl-w-full">
                        <div
                          aria-expanded="true"
                          tabIndex={0}
                          className="sl-panel__titlebar sl-flex sl-items-center sl-relative focus:sl-z-10 sl-text-base sl-leading-none sl-pr-4 sl-pl-3 sl-bg-canvas-200 sl-text-body sl-border-input focus:sl-border-primary sl-cursor-pointer sl-select-none"
                          role="button">
                          <div className="sl-flex sl-flex-1 sl-items-center sl-h-lg">
                            <div className="sl-flex sl-items-center sl-mr-1.5">
                              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" className="svg-inline--fa fa-caret-down fa-fw sl-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                <path fill="currentColor" d="M310.6 246.6l-127.1 128C176.4 380.9 168.2 384 160 384s-16.38-3.125-22.63-9.375l-127.1-128C.2244 237.5-2.516 223.7 2.438 211.8S19.07 192 32 192h255.1c12.94 0 24.62 7.781 29.58 19.75S319.8 237.5 310.6 246.6z" />
                              </svg>
                            </div>
                            Parameters
                          </div>
                        </div>
                        <div className="sl-panel__content-wrapper sl-bg-canvas-100" role="region">
                          <div className="sl-overflow-y-auto ParameterGrid OperationParametersContent sl-p-4">
                            <label aria-hidden="true" data-testid="param-label" htmlFor="id_signature_KiVgCOfQ" className="sl-text-base">
                              signature
                            </label>
                            <span className="sl-mx-3">:</span>
                            <div>
                              <div className="sl-flex sl-flex-1">
                                <div className="sl-input sl-form-group-border sl-flex-1 sl-relative">
                                  <input
                                    id="id_signature_KiVgCOfQ"
                                    aria-label="signature"
                                    placeholder="string"
                                    type="text"
                                    aria-required="true"
                                    className="sl-form-group-border sl-relative sl-w-full sl-h-md sl-text-base sl-pr-2.5 sl-pl-2.5 sl-rounded sl-border-transparent hover:sl-border-input focus:sl-border-primary sl-border"
                                    defaultValue
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sl-pb-1" />
                      <div className="SendButtonHolder sl-p-4 sl-pt-0">
                        <div className="sl-stack sl-stack--horizontal sl-stack--2 sl-flex sl-flex-row sl-items-center">
                          <button
                            type="button"
                            className="sl-button sl-form-group-border sl-h-sm sl-text-base sl-font-medium sl-px-1.5 sl-bg-primary hover:sl-bg-primary-dark active:sl-bg-primary-darker disabled:sl-bg-canvas-100 sl-text-on-primary disabled:sl-text-body sl-rounded sl-border-transparent sl-border disabled:sl-opacity-70">
                            Send API Request
                          </button>
                          <div className="sl-relative">
                            <button
                              type="button"
                              aria-label="Server"
                              aria-haspopup="true"
                              aria-expanded="false"
                              id="react-aria-4-39"
                              className="sl-button sl-form-group-border sl-h-sm sl-text-base sl-font-normal sl-px-1.5 sl-bg-transparent sl-rounded sl-border-transparent hover:sl-border-input focus:sl-border-primary active:sl-border-primary sl-border disabled:sl-opacity-60">
                              <div className="sl-mr-1">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="server" className="svg-inline--fa fa-server fa-fw fa-sm sl-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                  <path
                                    fill="currentColor"
                                    d="M480 288H32c-17.62 0-32 14.38-32 32v128c0 17.62 14.38 32 32 32h448c17.62 0 32-14.38 32-32v-128C512 302.4 497.6 288 480 288zM352 408c-13.25 0-24-10.75-24-24s10.75-24 24-24s24 10.75 24 24S365.3 408 352 408zM416 408c-13.25 0-24-10.75-24-24s10.75-24 24-24s24 10.75 24 24S429.3 408 416 408zM480 32H32C14.38 32 0 46.38 0 64v128c0 17.62 14.38 32 32 32h448c17.62 0 32-14.38 32-32V64C512 46.38 497.6 32 480 32zM352 152c-13.25 0-24-10.75-24-24S338.8 104 352 104S376 114.8 376 128S365.3 152 352 152zM416 152c-13.25 0-24-10.75-24-24S402.8 104 416 104S440 114.8 440 128S429.3 152 416 152z"
                                  />
                                </svg>
                              </div>
                              <div className="sl-flex sl-flex-1 sl-justify-items-start sl-items-center">
                                <div className="sl-pr-1">Ваш домен</div>
                              </div>
                              <div className="sl-text-xs sl--mr-0.5 sl-ml-1">
                                <div className="sl-pt-0.5 sl-pr-0.5">
                                  <i role="img" aria-hidden="true" className="sl-icon fal fa-chevron-down fa-xs" />
                                </div>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sl-panel sl-outline-none sl-w-full sl-rounded-lg">
                  <div className="sl-panel__titlebar sl-flex sl-items-center sl-relative focus:sl-z-10 sl-text-base sl-leading-none sl-pr-3 sl-pl-4 sl-bg-canvas-200 sl-text-body sl-border-input focus:sl-border-primary sl-select-none">
                    <div className="sl-flex sl-flex-1 sl-items-center sl-h-lg">
                      <div className="sl--ml-2">
                        <button
                          type="button"
                          aria-label="Request Sample Language"
                          aria-haspopup="true"
                          aria-expanded="false"
                          id="react-aria-4-41"
                          className="sl-button sl-form-group-border sl-h-sm sl-text-base sl-font-medium sl-px-1.5 hover:sl-bg-canvas-50 active:sl-bg-canvas-100 sl-text-muted hover:sl-text-body focus:sl-text-body sl-rounded sl-border-transparent sl-border disabled:sl-opacity-70">
                          Request Sample: Shell / cURL
                          <div className="sl-text-xs sl--mr-0.5 sl-ml-1">
                            <i role="img" aria-hidden="true" className="sl-icon fal fa-chevron-down fa-fw" />
                          </div>
                        </button>
                      </div>
                    </div>
                    <button type="button" className="sl-button sl-form-group-border sl-h-sm sl-text-base sl-font-medium sl-px-1.5 hover:sl-bg-canvas-50 active:sl-bg-canvas-100 sl-text-muted hover:sl-text-body focus:sl-text-body sl-rounded sl-border-transparent sl-border disabled:sl-opacity-70">
                      <div className="sl-mx-0">
                        <i role="img" aria-hidden="true" className="sl-icon fal fa-copy fa-fw fa-sm" />
                      </div>
                    </button>
                  </div>
                  <div className="sl-panel__content-wrapper sl-bg-canvas-100">
                    <div className="sl-panel__content sl-p-0">
                      <pre
                        tabIndex={0}
                        aria-label="curl --request GET \
  --url https://your.domain.com/api/status-list \
  --header 'Accept: application/json' \
  --header 'signature: '"
                        className="sl-code-viewer sl-grid sl-overflow-x-hidden sl-overflow-y-hidden sl-relative sl-outline-none sl-group"
                        role="group">
                        <div className="sl-code-viewer__scroller sl-overflow-x-auto sl-overflow-y-auto" style={{ maxHeight: '400px' }}>
                          <div className="sl-code-highlight prism-code language-bash" style={{ padding: '12px 15px', fontFamily: 'var(--font-code)', fontSize: 'var(--fs-code)', lineHeight: 'var(--lh-code)' }}>
                            <div className="sl-flex">
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token function" style={{ color: 'rgb(111, 66, 193)' }}>
                                  curl
                                </span>
                                <span className="token plain"> --request GET </span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  \
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'  '}--url https://your.domain.com/api/status-list </span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  \
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'  '}--header </span>
                                <span className="token string" style={{ color: 'rgb(3, 47, 98)' }}>
                                  'Accept: application/json'
                                </span>
                                <span className="token plain"> </span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  \
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'  '}--header </span>
                                <span className="token string" style={{ color: 'rgb(3, 47, 98)' }}>
                                  'signature: '
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </pre>
                    </div>
                  </div>
                </div>
                <div className="sl-panel sl-outline-none sl-w-full sl-rounded-lg">
                  <div className="sl-panel__titlebar sl-flex sl-items-center sl-relative focus:sl-z-10 sl-text-base sl-leading-none sl-pr-3 sl-pl-4 sl-bg-canvas-200 sl-text-body sl-border-input focus:sl-border-primary sl-select-none">
                    <div className="sl-flex sl-flex-1 sl-items-center sl-h-lg">
                      <span className="sl-text-body">Response Example</span>
                    </div>
                    <button type="button" className="sl-button sl-form-group-border sl-h-sm sl-text-base sl-font-medium sl-px-1.5 hover:sl-bg-canvas-50 active:sl-bg-canvas-100 sl-text-muted hover:sl-text-body focus:sl-text-body sl-rounded sl-border-transparent sl-border disabled:sl-opacity-70">
                      <div className="sl-mx-0">
                        <i role="img" aria-hidden="true" className="sl-icon fal fa-copy fa-fw fa-sm" />
                      </div>
                    </button>
                  </div>
                  <div className="sl-panel__content-wrapper sl-bg-canvas-100">
                    <div className="sl-panel__content sl-p-0">
                      <pre
                        tabIndex={0}
                        aria-label='{
  "ERROR": [
    111,
    222
  ],
  "COMPLETED": [
    "81b50ca9-46b6-43e7-8c2e-e84513ffb1d6",
    "962bb355-744d-4623-84a4-82b7ebacdb3a"
  ],
  "PROCESSING": [
    "in-progress-1",
    "processing-1"
  ]
}'
                        className="sl-code-viewer sl-grid sl-overflow-x-hidden sl-overflow-y-hidden sl-relative sl-outline-none sl-group"
                        role="group">
                        <div className="sl-code-viewer__scroller sl-overflow-x-auto sl-overflow-y-auto" style={{ maxHeight: '500px' }}>
                          <div className="sl-code-highlight prism-code language-json" style={{ padding: '12px 15px', fontFamily: 'var(--font-code)', fontSize: 'var(--fs-code)', lineHeight: 'var(--lh-code)' }}>
                            <div className="sl-flex">
                              <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                1
                              </div>
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  {'{'}
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                2
                              </div>
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'  '}</span>
                                <span className="token property" style={{ color: 'rgb(24, 54, 145)' }}>
                                  "ERROR"
                                </span>
                                <span className="token operator" style={{ color: 'rgb(215, 58, 73)' }}>
                                  :
                                </span>
                                <span className="token plain"> </span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  [
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                3
                              </div>
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'    '}</span>
                                <span className="token number" style={{ color: 'rgb(0, 92, 197)' }}>
                                  111
                                </span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  ,
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                4
                              </div>
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'    '}</span>
                                <span className="token number" style={{ color: 'rgb(0, 92, 197)' }}>
                                  222
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                5
                              </div>
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'  '}</span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  ]
                                </span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  ,
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                6
                              </div>
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'  '}</span>
                                <span className="token property" style={{ color: 'rgb(24, 54, 145)' }}>
                                  "COMPLETED"
                                </span>
                                <span className="token operator" style={{ color: 'rgb(215, 58, 73)' }}>
                                  :
                                </span>
                                <span className="token plain"> </span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  [
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                7
                              </div>
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'    '}</span>
                                <span className="token string" style={{ color: 'rgb(3, 47, 98)' }}>
                                  "81b50ca9-46b6-43e7-8c2e-e84513ffb1d6"
                                </span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  ,
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                8
                              </div>
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'    '}</span>
                                <span className="token string" style={{ color: 'rgb(3, 47, 98)' }}>
                                  "962bb355-744d-4623-84a4-82b7ebacdb3a"
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                9
                              </div>
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'  '}</span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  ]
                                </span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  ,
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                10
                              </div>
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'  '}</span>
                                <span className="token property" style={{ color: 'rgb(24, 54, 145)' }}>
                                  "PROCESSING"
                                </span>
                                <span className="token operator" style={{ color: 'rgb(215, 58, 73)' }}>
                                  :
                                </span>
                                <span className="token plain"> </span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  [
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                11
                              </div>
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'    '}</span>
                                <span className="token string" style={{ color: 'rgb(3, 47, 98)' }}>
                                  "in-progress-1"
                                </span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  ,
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                12
                              </div>
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'    '}</span>
                                <span className="token string" style={{ color: 'rgb(3, 47, 98)' }}>
                                  "processing-1"
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                13
                              </div>
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'  '}</span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  ]
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                14
                              </div>
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain" />
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  {'}'}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </pre>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TwoStepPage;
