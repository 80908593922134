import { useRoutes } from 'react-router';
import Layout from './components/Layout';
import StartPage from './pages/StartPage';
import './style.css';
import OneStepPage from './pages/OneStepPage';
import TwoStepPage from './pages/TwoStepPage';
import ThreeStepPage from './pages/ThreeStepPage';
import FourStepPage from './pages/FourStepPage';
import StartHelperPage from './pages/StartHelperPage';
import OneHelperPage from './pages/OneHelperPage';
import TwoHelperPage from './pages/TwoHelperPage';
function App() {
  let routes = useRoutes([
    // {
    //   path: '*',
    //   element: (
    //     <Layout>
    //       <StartPage />
    //     </Layout>
    //   ),
    // },
    {
      path: '/',
      element: (
        <Layout>
          <StartPage />
        </Layout>
      ),
    },
    {
      path: '/poluchenie-zakazov-iz-bota-huk',
      element: (
        <Layout>
          <OneStepPage />
        </Layout>
      ),
    },
    {
      path: '/otpravka-vashih-statusov',
      element: (
        <Layout>
          <TwoStepPage />
        </Layout>
      ),
    },
    {
      path: '/otpravka-statusov-zakaza',
      element: (
        <Layout>
          <ThreeStepPage />
        </Layout>
      ),
    },
    {
      path: '/otpravka-aktualnyh-dannyh-po-igram-serveram-i-paketam',
      element: (
        <Layout>
          <FourStepPage />
        </Layout>
      ),
    },
    {
      path: '/api-pomoshhniki',
      element: (
        <Layout>
          <StartHelperPage />
        </Layout>
      ),
    },
    {
      path: '/pomoshhnik-validacziya-spiska-statusov',
      element: (
        <Layout>
          <OneHelperPage />
        </Layout>
      ),
    },
    {
      path: '/pomoshhnik-validacziya-spiska-tovarov',
      element: (
        <Layout>
          <TwoHelperPage />
        </Layout>
      ),
    },
  ]);

  return routes;
}

export default App;
