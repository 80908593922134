import React, { useEffect, useState } from 'react';
const ThreeStepPage = () => {
  return (
    <>
      <div data-test="project-page" className="sl-w-full sl-mx-auto sl-px-20 sl-pt-20 sl-pb-8" style={{ maxWidth: '1500px' }}>
        <div className="sl-stack sl-stack--vertical sl-stack--8 HttpOperation sl-flex sl-flex-col sl-items-stretch sl-w-full">
          <div className="sl-stack sl-stack--vertical sl-stack--5 sl-flex sl-flex-col sl-items-stretch">
            <div className="sl-relative">
              <div className="sl-stack sl-stack--horizontal sl-stack--5 sl-flex sl-flex-row sl-items-center">
                <h1 className="sl-text-5xl sl-leading-tight sl-font-prose sl-font-semibold sl-text-heading">3. Отправка статусов заказа</h1>
                <div className="sl-stack sl-stack--horizontal sl-stack--2 sl-flex sl-flex-row sl-items-center" />
              </div>
            </div>
            <div className="sl-relative">
              <div>
                <div title="https://your.domain.com/api/order-list" className="sl-stack sl-stack--horizontal sl-stack--3 sl-inline-flex sl-flex-row sl-items-center sl-max-w-full sl-font-mono sl-py-2 sl-pr-4 sl-pl-2.5 sl-bg-canvas-50 sl-rounded-lg">
                  <div className="sl-text-lg sl-font-semibold sl-uppercase sl-px-2.5 sl-py-1 sl-bg-primary sl-text-on-primary sl-rounded-lg">post</div>
                  <div className="sl-flex sl-overflow-x-hidden sl-text-lg sl-select-all">
                    <div dir="rtl" className="sl-overflow-x-hidden sl-truncate sl-text-muted">
                      <span dir="ltr" style={{ unicodeBidi: 'bidi-override' }}>
                        https://your.domain.com/api
                      </span>
                    </div>
                    <div className="sl-flex-1 sl-font-semibold">/order-list</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sl-flex">
            <div data-testid="two-column-left" className="sl-flex-1 sl-w-0">
              <div className="sl-stack sl-stack--vertical sl-stack--10 sl-flex sl-flex-col sl-items-stretch">
                <div className="sl-relative">
                  <div className="sl-prose sl-markdown-viewer HttpOperation__Description">
                    <p>Создайте этот эндпоинт, чтобы мы смогли обновить статусы заказов в боте.</p>
                  </div>
                </div>
                <div className="sl-stack sl-stack--vertical sl-stack--8 sl-flex sl-flex-col sl-items-stretch">
                  <div className="sl-flex sl-w-full">
                    <h2 id="Request" aria-label="Request" className="sl-link-heading sl-py-1 sl-pr-6 sl-text-4xl sl-leading-tight sl-font-prose sl-font-bold sl-text-heading">
                      <a href="#Request" className="sl-link sl-link-heading__link sl-inline-flex sl-items-center sl-text-current">
                        <div>Request</div>
                        <div className="sl-link-heading__icon sl-text-base sl-ml-4 sl-text-muted">
                          <i role="img" aria-hidden="true" className="sl-icon fal fa-link" />
                        </div>
                      </a>
                    </h2>
                    <div className="sl-flex sl-flex-grow sl-self-center sl-py-1" style={{ minWidth: '0px' }} />
                  </div>
                  <div className="sl-stack sl-stack--vertical sl-stack--5 sl-flex sl-flex-col sl-items-stretch">
                    <div className="sl-flex sl-w-full">
                      <h3 id="request-headers" aria-label="Headers" className="sl-link-heading sl-py-1 sl-pr-6 sl-text-2xl sl-leading-snug sl-font-prose sl-font-semibold sl-text-heading">
                        <a href="#request-headers" className="sl-link sl-link-heading__link sl-inline-flex sl-items-center sl-text-current">
                          <div>Headers</div>
                          <div className="sl-link-heading__icon sl-text-base sl-ml-4 sl-text-muted">
                            <i role="img" aria-hidden="true" className="sl-icon fal fa-link" />
                          </div>
                        </a>
                      </h3>
                      <div className="sl-flex sl-flex-grow sl-self-center sl-py-1" style={{ minWidth: '0px' }} />
                    </div>
                    <div className id="mosaic-provider-react-aria-17-1">
                      <div data-overlay-container="true" className>
                        <div className="JsonSchemaViewer">
                          <div />
                          <div data-level={0} className="sl-text-sm">
                            <div data-id="e16b3057d97ec" data-test="schema-row" className="sl-flex sl-relative sl-max-w-full sl-py-2">
                              <div className="sl-stack sl-stack--vertical sl-stack--1 sl-flex sl-flex-1 sl-flex-col sl-items-stretch sl-max-w-full">
                                <div className="sl-flex sl-items-center sl-max-w-full">
                                  <div className="sl-flex sl-items-baseline sl-text-base">
                                    <div data-test="property-name-signature" className="sl-font-mono sl-font-semibold sl-mr-2">
                                      signature
                                    </div>
                                    <span data-test="property-type" className="sl-truncate sl-text-muted">
                                      string
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sl-stack sl-stack--vertical sl-stack--6 sl-flex sl-flex-col sl-items-stretch">
                    <div className="sl-flex sl-w-full">
                      <h3 id="request-body" aria-label="Body" className="sl-link-heading sl-py-1 sl-pr-6 sl-text-2xl sl-leading-snug sl-font-prose sl-font-semibold sl-text-heading">
                        <a href="#request-body" className="sl-link sl-link-heading__link sl-inline-flex sl-items-center sl-text-current">
                          <div>Body</div>
                          <div className="sl-link-heading__icon sl-text-base sl-ml-4 sl-text-muted">
                            <i role="img" aria-hidden="true" className="sl-icon fal fa-link" />
                          </div>
                        </a>
                      </h3>
                      <div className="sl-flex sl-flex-grow sl-self-center sl-py-1" style={{ minWidth: '0px' }}>
                        <div className="sl-flex sl-flex-1 sl-justify-end">
                          <div className="sl-select sl-relative">
                            <div aria-hidden="true" style={{ border: '0px', clip: 'rect(0px, 0px, 0px, 0px)', clipPath: 'inset(50%)', height: '1px', margin: '0px -1px -1px 0px', overflow: 'hidden', padding: '0px', position: 'absolute', width: '1px', whiteSpace: 'nowrap' }}>
                              <input type="text" tabIndex={0} style={{ fontSize: '16px' }} />
                              <label>
                                <select size={1} tabIndex={-1}>
                                  <option />
                                  <option value={0}>application/json</option>
                                </select>
                              </label>
                            </div>
                            <div className="sl-relative">
                              <button
                                aria-label="Request Body Content Type"
                                aria-haspopup="listbox"
                                aria-expanded="false"
                                id="react-aria-17-183"
                                aria-labelledby="react-aria-17-183 react-aria-17-185"
                                type="button"
                                className="sl-button sl-form-group-border sl-w-full sl-h-sm sl-text-base sl-font-normal sl-px-1.5 sl-bg-transparent sl-rounded sl-border-transparent hover:sl-border-input focus:sl-border-primary active:sl-border-primary sl-border disabled:sl-opacity-60">
                                <div className="sl-flex sl-flex-1 sl-justify-items-start sl-items-center">
                                  <div className="sl-pr-1">application/json</div>
                                </div>
                                <div className="sl-text-xs sl--mr-0.5 sl-ml-1">
                                  <div className="sl-pt-0.5 sl-pr-0.5">
                                    <i role="img" aria-hidden="true" className="sl-icon fal fa-chevron-down fa-xs" />
                                  </div>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sl-relative">
                      <div className="sl-prose sl-markdown-viewer ">
                        <p>В теле запроса содержится список заказов, статусы которых надо обновить.</p>
                      </div>
                    </div>
                    <div className id="mosaic-provider-react-aria-18-1">
                      <div data-overlay-container="true" className>
                        <div className="JsonSchemaViewer">
                          <div />
                          <div data-level={0} className="sl-text-sm sl-ml-px sl-border-l">
                            <div data-id="q9typkjffwlvp" data-test="schema-row" className="sl-flex sl-relative sl-max-w-full sl-py-2 sl-pl-3">
                              <div className="sl-w-3 sl-mt-2 sl-mr-3 sl--ml-3 sl-border-t" />
                              <div className="sl-stack sl-stack--vertical sl-stack--1 sl-flex sl-flex-1 sl-flex-col sl-items-stretch sl-max-w-full">
                                <div className="sl-flex sl-items-center sl-max-w-full">
                                  <div className="sl-flex sl-items-baseline sl-text-base">
                                    <div data-test="property-name-orders" className="sl-font-mono sl-font-semibold sl-mr-2">
                                      orders
                                    </div>
                                    <span data-test="property-type" className="sl-truncate sl-text-muted">
                                      array[string]
                                    </span>
                                  </div>
                                </div>
                                <div data-test="property-description" className="sl-prose sl-markdown-viewer " style={{ fontSize: '12px' }}>
                                  <p>Массив ID заказов в нашей системе, статусы которых надо обновить</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sl-stack sl-stack--vertical sl-stack--1 sl-flex sl-flex-col sl-w-full sl-h-auto">
                  <div className="sl-flex sl-w-full">
                    <h2 id="Responses" aria-label="Responses" className="sl-link-heading sl-py-1 sl-pr-6 sl-text-4xl sl-leading-tight sl-font-prose sl-font-bold sl-text-heading">
                      <a href="#Responses" className="sl-link sl-link-heading__link sl-inline-flex sl-items-center sl-text-current">
                        <div>Responses</div>
                        <div className="sl-link-heading__icon sl-text-base sl-ml-4 sl-text-muted">
                          <i role="img" aria-hidden="true" className="sl-icon fal fa-link" />
                        </div>
                      </a>
                    </h2>
                    <div className="sl-flex sl-flex-grow sl-self-center sl-py-1" style={{ minWidth: '0px' }}>
                      <div id="react-aria-20-213" aria-orientation="horizontal" className="sl-stack sl-stack--horizontal sl-stack--2 sl-flex sl-overflow-x-auto sl-overflow-y-hidden sl-flex-row sl-h-auto sl-text-lg" role="tablist">
                        <div tabIndex={0} data-key={200} id="react-aria-20-213-tab-200" aria-selected="true" aria-controls="react-aria-20-213-tabpanel-200" className="sl-font-medium sl-p-1 sl-px-2 sl-py-1 sl-bg-success sl-text-on-primary sl-rounded-lg sl-border-light sl-cursor" role="tab">
                          200
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="react-aria-18-192-tabpanel-200" aria-labelledby="react-aria-20-213-tab-200" className="sl-flex-1 sl-py-0" role="tabpanel">
                    <div className="sl-stack sl-stack--vertical sl-stack--8 sl-flex sl-flex-col sl-items-stretch sl-pt-8">
                      <div className="sl-relative">
                        <div className="sl-prose sl-markdown-viewer ">
                          <p>Ответ должен содержать валидный массив заказов, с актуальными статусами.</p>
                        </div>
                      </div>
                      <div className="sl-flex sl-w-full">
                        <h3 id="response-body" aria-label="Body" className="sl-link-heading sl-py-1 sl-pr-6 sl-text-2xl sl-leading-snug sl-font-prose sl-font-semibold sl-text-heading">
                          <a href="#response-body" className="sl-link sl-link-heading__link sl-inline-flex sl-items-center sl-text-current">
                            <div>Body</div>
                            <div className="sl-link-heading__icon sl-text-base sl-ml-4 sl-text-muted">
                              <i role="img" aria-hidden="true" className="sl-icon fal fa-link" />
                            </div>
                          </a>
                        </h3>
                        <div className="sl-flex sl-flex-grow sl-self-center sl-py-1" style={{ minWidth: '0px' }}>
                          <div className="sl-flex sl-flex-1 sl-justify-end">
                            <div className="sl-select sl-relative">
                              <div aria-hidden="true" style={{ border: '0px', clip: 'rect(0px, 0px, 0px, 0px)', clipPath: 'inset(50%)', height: '1px', margin: '0px -1px -1px 0px', overflow: 'hidden', padding: '0px', position: 'absolute', width: '1px', whiteSpace: 'nowrap' }}>
                                <input type="text" tabIndex={0} style={{ fontSize: '16px' }} />
                                <label>
                                  <select size={1} tabIndex={-1}>
                                    <option />
                                    <option value={0}>application/json</option>
                                  </select>
                                </label>
                              </div>
                              <div className="sl-relative">
                                <button
                                  aria-label="Response Body Content Type"
                                  aria-haspopup="listbox"
                                  aria-expanded="false"
                                  id="react-aria-20-216"
                                  aria-labelledby="react-aria-20-216 react-aria-20-218"
                                  type="button"
                                  className="sl-button sl-form-group-border sl-w-full sl-h-sm sl-text-base sl-font-normal sl-px-1.5 sl-bg-transparent sl-rounded sl-border-transparent hover:sl-border-input focus:sl-border-primary active:sl-border-primary sl-border disabled:sl-opacity-60">
                                  <div className="sl-flex sl-flex-1 sl-justify-items-start sl-items-center">
                                    <div className="sl-pr-1">application/json</div>
                                  </div>
                                  <div className="sl-text-xs sl--mr-0.5 sl-ml-1">
                                    <div className="sl-pt-0.5 sl-pr-0.5">
                                      <i role="img" aria-hidden="true" className="sl-icon fal fa-chevron-down fa-xs" />
                                    </div>
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className id="mosaic-provider-react-aria-21-1">
                        <div data-overlay-container="true" className>
                          <div className="JsonSchemaViewer">
                            <div />
                            <div className="sl-text-base sl-font-mono sl-font-semibold sl-pb-4">array of:</div>
                            <div className="sl-text-sm sl--mt-2 sl-mb-1">
                              <div data-test="property-validation" className="sl-stack sl-stack--horizontal sl-stack--2 sl-flex sl-flex-row sl-items-baseline sl-text-muted">
                                <span>Example:</span>
                                <div className="sl-flex sl-flex-1 sl-flex-wrap" style={{ gap: '4px' }}>
                                  <span className="sl-max-w-full sl-break-all sl-px-1 sl-bg-canvas-tint sl-text-muted sl-rounded sl-border">
                                    [{'{'}"id":29,"status":"in-progress","orderId":"7877-453513-8790"{'}'},{'{'}"id":30,"status":"completed","orderId":"7877-453513-6456"{'}'},{'{'}"id":31,"status":"error","orderId":"7877-453513-2434"{'}'}]
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div data-level={0} className="sl-text-sm sl-ml-px sl-border-l">
                              <div data-id="b30ad2efc03b9" data-test="schema-row" className="sl-flex sl-relative sl-max-w-full sl-py-2 sl-pl-3">
                                <div className="sl-w-3 sl-mt-2 sl-mr-3 sl--ml-3 sl-border-t" />
                                <div className="sl-stack sl-stack--vertical sl-stack--1 sl-flex sl-flex-1 sl-flex-col sl-items-stretch sl-max-w-full">
                                  <div className="sl-flex sl-items-center sl-max-w-full">
                                    <div className="sl-flex sl-items-baseline sl-text-base">
                                      <div data-test="property-name-id" className="sl-font-mono sl-font-semibold sl-mr-2">
                                        id
                                      </div>
                                      <div className="sl-select sl-relative">
                                        <div aria-hidden="true" style={{ border: '0px', clip: 'rect(0px, 0px, 0px, 0px)', clipPath: 'inset(50%)', height: '1px', margin: '0px -1px -1px 0px', overflow: 'hidden', padding: '0px', position: 'absolute', width: '1px', whiteSpace: 'nowrap' }}>
                                          <input type="text" tabIndex={0} style={{ fontSize: '16px' }} />
                                          <label>
                                            <select size={2} tabIndex={-1}>
                                              <option />
                                              <option value={0}>string</option>
                                              <option value={1}>integer</option>
                                            </select>
                                          </label>
                                        </div>
                                        <div className="sl-relative">
                                          <button
                                            aria-label="Pick a type"
                                            aria-haspopup="listbox"
                                            aria-expanded="false"
                                            id="react-aria-21-4"
                                            aria-labelledby="react-aria-21-4 react-aria-21-6"
                                            type="button"
                                            className="sl-button sl-form-group-border sl-w-full sl-h-sm sl-text-base sl-font-normal sl-px-1.5 sl-bg-transparent sl-rounded sl-border-transparent hover:sl-border-input focus:sl-border-primary active:sl-border-primary sl-border disabled:sl-opacity-60">
                                            <div className="sl-flex sl-flex-1 sl-justify-items-start sl-items-center">
                                              <div className="sl-pr-1">one of: string</div>
                                            </div>
                                            <div className="sl-text-xs sl--mr-0.5 sl-ml-1">
                                              <div className="sl-pt-0.5 sl-pr-0.5">
                                                <i role="img" aria-hidden="true" className="sl-icon fal fa-chevron-down fa-xs" />
                                              </div>
                                            </div>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div data-test="property-description" className="sl-prose sl-markdown-viewer " style={{ fontSize: '12px' }}>
                                    <p>ID вашего заказа</p>
                                  </div>
                                </div>
                              </div>
                              <div data-id="fm0bmswbtvz87" data-test="schema-row" className="sl-flex sl-relative sl-max-w-full sl-py-2 sl-pl-3">
                                <div className="sl-w-3 sl-mt-2 sl-mr-3 sl--ml-3 sl-border-t" />
                                <div className="sl-stack sl-stack--vertical sl-stack--1 sl-flex sl-flex-1 sl-flex-col sl-items-stretch sl-max-w-full">
                                  <div className="sl-flex sl-items-center sl-max-w-full">
                                    <div className="sl-flex sl-items-baseline sl-text-base">
                                      <div data-test="property-name-orderId" className="sl-font-mono sl-font-semibold sl-mr-2">
                                        orderId
                                      </div>
                                      <span data-test="property-type" className="sl-truncate sl-text-muted">
                                        string
                                      </span>
                                    </div>
                                  </div>
                                  <div data-test="property-description" className="sl-prose sl-markdown-viewer " style={{ fontSize: '12px' }}>
                                    <p>
                                      ID нашего заказа, который хранится в созданом вами поле (например{' '}
                                      <code className="sl-font-mono sl-font-medium sl-mx-0.5 sl-px-1 sl-py-0.5 sl-bg-code sl-text-on-code sl-rounded sl-border" style={{ fontSize: '0.8125em' }}>
                                        externalOrderId
                                      </code>
                                      ), в таблице заказов
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div data-id="2664acdfa9c58" data-test="schema-row" className="sl-flex sl-relative sl-max-w-full sl-py-2 sl-pl-3">
                                <div className="sl-w-3 sl-mt-2 sl-mr-3 sl--ml-3 sl-border-t" />
                                <div className="sl-stack sl-stack--vertical sl-stack--1 sl-flex sl-flex-1 sl-flex-col sl-items-stretch sl-max-w-full">
                                  <div className="sl-flex sl-items-center sl-max-w-full">
                                    <div className="sl-flex sl-items-baseline sl-text-base">
                                      <div data-test="property-name-status" className="sl-font-mono sl-font-semibold sl-mr-2">
                                        status
                                      </div>
                                      <div className="sl-select sl-relative">
                                        <div aria-hidden="true" style={{ border: '0px', clip: 'rect(0px, 0px, 0px, 0px)', clipPath: 'inset(50%)', height: '1px', margin: '0px -1px -1px 0px', overflow: 'hidden', padding: '0px', position: 'absolute', width: '1px', whiteSpace: 'nowrap' }}>
                                          <input type="text" tabIndex={0} style={{ fontSize: '16px' }} />
                                          <label>
                                            <select size={2} tabIndex={-1}>
                                              <option />
                                              <option value={0}>string</option>
                                              <option value={1}>integer</option>
                                            </select>
                                          </label>
                                        </div>
                                        <div className="sl-relative">
                                          <button
                                            aria-label="Pick a type"
                                            aria-haspopup="listbox"
                                            aria-expanded="false"
                                            id="react-aria-21-10"
                                            aria-labelledby="react-aria-21-10 react-aria-21-12"
                                            type="button"
                                            className="sl-button sl-form-group-border sl-w-full sl-h-sm sl-text-base sl-font-normal sl-px-1.5 sl-bg-transparent sl-rounded sl-border-transparent hover:sl-border-input focus:sl-border-primary active:sl-border-primary sl-border disabled:sl-opacity-60">
                                            <div className="sl-flex sl-flex-1 sl-justify-items-start sl-items-center">
                                              <div className="sl-pr-1">one of: string</div>
                                            </div>
                                            <div className="sl-text-xs sl--mr-0.5 sl-ml-1">
                                              <div className="sl-pt-0.5 sl-pr-0.5">
                                                <i role="img" aria-hidden="true" className="sl-icon fal fa-chevron-down fa-xs" />
                                              </div>
                                            </div>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div data-test="property-description" className="sl-prose sl-markdown-viewer " style={{ fontSize: '12px' }}>
                                    <p>Актуальный статус заказа в вашей систем</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div data-testid="two-column-right" className="sl-relative sl-w-2/5 sl-ml-16" style={{ maxWidth: '500px' }}>
              <div className="sl-stack sl-stack--vertical sl-stack--6 sl-flex sl-flex-col sl-items-stretch">
                <div className="sl-inverted">
                  <div className="sl-overflow-y-hidden sl-rounded-lg">
                    <div className="TryItPanel sl-bg-canvas-100 sl-rounded-lg">
                      <div className="sl-panel sl-outline-none sl-w-full">
                        <div
                          aria-expanded="true"
                          tabIndex={0}
                          className="sl-panel__titlebar sl-flex sl-items-center sl-relative focus:sl-z-10 sl-text-base sl-leading-none sl-pr-4 sl-pl-3 sl-bg-canvas-200 sl-text-body sl-border-input focus:sl-border-primary sl-cursor-pointer sl-select-none"
                          role="button">
                          <div className="sl-flex sl-flex-1 sl-items-center sl-h-lg">
                            <div className="sl-flex sl-items-center sl-mr-1.5">
                              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" className="svg-inline--fa fa-caret-down fa-fw sl-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                <path fill="currentColor" d="M310.6 246.6l-127.1 128C176.4 380.9 168.2 384 160 384s-16.38-3.125-22.63-9.375l-127.1-128C.2244 237.5-2.516 223.7 2.438 211.8S19.07 192 32 192h255.1c12.94 0 24.62 7.781 29.58 19.75S319.8 237.5 310.6 246.6z" />
                              </svg>
                            </div>
                            Parameters
                          </div>
                        </div>
                        <div className="sl-panel__content-wrapper sl-bg-canvas-100" role="region">
                          <div className="sl-overflow-y-auto ParameterGrid OperationParametersContent sl-p-4">
                            <label aria-hidden="true" data-testid="param-label" htmlFor="id_signature_nwLj-VVq" className="sl-text-base">
                              signature
                            </label>
                            <span className="sl-mx-3">:</span>
                            <div>
                              <div className="sl-flex sl-flex-1">
                                <div className="sl-input sl-form-group-border sl-flex-1 sl-relative">
                                  <input
                                    id="id_signature_nwLj-VVq"
                                    aria-label="signature"
                                    placeholder="string"
                                    type="text"
                                    aria-required="true"
                                    className="sl-form-group-border sl-relative sl-w-full sl-h-md sl-text-base sl-pr-2.5 sl-pl-2.5 sl-rounded sl-border-transparent hover:sl-border-input focus:sl-border-primary sl-border"
                                    defaultValue
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sl-pb-1">
                        <div className="sl-panel sl-outline-none sl-w-full">
                          <div
                            aria-expanded="true"
                            tabIndex={0}
                            className="sl-panel__titlebar sl-flex sl-items-center sl-relative focus:sl-z-10 sl-text-base sl-leading-none sl-pr-4 sl-pl-3 sl-bg-canvas-200 sl-text-body sl-border-input focus:sl-border-primary sl-cursor-pointer sl-select-none"
                            role="button">
                            <div className="sl-flex sl-flex-1 sl-items-center sl-h-lg">
                              <div className="sl-flex sl-items-center sl-mr-1.5">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" className="svg-inline--fa fa-caret-down fa-fw sl-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                  <path fill="currentColor" d="M310.6 246.6l-127.1 128C176.4 380.9 168.2 384 160 384s-16.38-3.125-22.63-9.375l-127.1-128C.2244 237.5-2.516 223.7 2.438 211.8S19.07 192 32 192h255.1c12.94 0 24.62 7.781 29.58 19.75S319.8 237.5 310.6 246.6z" />
                                </svg>
                              </div>
                              Body
                            </div>
                          </div>
                          <div className="sl-panel__content-wrapper sl-bg-canvas-100" role="region">
                            <div className="TextRequestBody sl-p-4">
                              <div id="react-aria-21-221" className="sl-code-editor" style={{ fontFamily: 'var(--font-code)', fontSize: '12px', lineHeight: 'var(--lh-code)' }}>
                                <div style={{ position: 'relative', textAlign: 'left', boxSizing: 'border-box', padding: '0px', overflow: 'hidden', fontSize: '12px' }}>
                                  <textarea
                                    className="npm__react-simple-code-editor__textarea sl-border-transparent hover:sl-border-input focus:sl-border-primary"
                                    autoCapitalize="off"
                                    autoComplete="off"
                                    autoCorrect="off"
                                    spellCheck="false"
                                    data-gramm="false"
                                    style={{
                                      margin: '0px',
                                      background: 'none',
                                      boxSizing: 'inherit',
                                      display: 'inherit',
                                      fontFamily: 'inherit',
                                      fontSize: 'inherit',
                                      fontStyle: 'inherit',
                                      fontVariantLigatures: 'inherit',
                                      fontWeight: 'inherit',
                                      letterSpacing: 'inherit',
                                      lineHeight: 'inherit',
                                      tabSize: 'inherit',
                                      textIndent: 'inherit',
                                      textRendering: 'inherit',
                                      textTransform: 'inherit',
                                      whiteSpace: 'pre-wrap',
                                      wordBreak: 'keep-all',
                                      overflowWrap: 'break-word',
                                      position: 'absolute',
                                      top: '0px',
                                      left: '0px',
                                      height: '100%',
                                      width: '100%',
                                      resize: 'none',
                                      color: 'inherit',
                                      overflow: 'hidden',
                                      WebkitFontSmoothing: 'antialiased',
                                      WebkitTextFillColor: 'transparent',
                                      padding: '0px',
                                    }}
                                    defaultValue={'{\n  "orders": [\n    "7877-453513-8790",\n    "7877-453513-7345",\n    "7877-453513-2456"\n  ]\n}'}
                                  />
                                  <pre
                                    aria-hidden="true"
                                    style={{
                                      margin: '0px',
                                      background: 'none',
                                      boxSizing: 'inherit',
                                      display: 'inherit',
                                      fontFamily: 'inherit',
                                      fontSize: 'inherit',
                                      fontStyle: 'inherit',
                                      fontVariantLigatures: 'inherit',
                                      fontWeight: 'inherit',
                                      letterSpacing: 'inherit',
                                      lineHeight: 'inherit',
                                      tabSize: 'inherit',
                                      textIndent: 'inherit',
                                      textRendering: 'inherit',
                                      textTransform: 'inherit',
                                      whiteSpace: 'pre-wrap',
                                      wordBreak: 'keep-all',
                                      overflowWrap: 'break-word',
                                      position: 'relative',
                                      pointerEvents: 'none',
                                      padding: '0px',
                                    }}>
                                    <div className="sl-code-highlight prism-code language-json" style={{ fontSize: '12px' }}>
                                      <div className="sl-flex">
                                        <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                          1
                                        </div>
                                        <div className="sl-flex-1 sl-break-all">
                                          <span className="token punctuation" style={{ color: 'inherit' }}>
                                            {'{'}
                                          </span>
                                          <span className="token plain" />
                                        </div>
                                      </div>
                                      <div className="sl-flex">
                                        <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                          2
                                        </div>
                                        <div className="sl-flex-1 sl-break-all">
                                          <span className="token plain">{'  '}</span>
                                          <span className="token property" style={{ color: 'rgb(128, 203, 196)' }}>
                                            "orders"
                                          </span>
                                          <span className="token operator" style={{ color: 'rgb(255, 123, 114)' }}>
                                            :
                                          </span>
                                          <span className="token plain"> </span>
                                          <span className="token punctuation" style={{ color: 'inherit' }}>
                                            [
                                          </span>
                                          <span className="token plain" />
                                        </div>
                                      </div>
                                      <div className="sl-flex">
                                        <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                          3
                                        </div>
                                        <div className="sl-flex-1 sl-break-all">
                                          <span className="token plain">{'    '}</span>
                                          <span className="token string" style={{ color: 'rgb(165, 214, 255)' }}>
                                            "7877-453513-8790"
                                          </span>
                                          <span className="token punctuation" style={{ color: 'inherit' }}>
                                            ,
                                          </span>
                                          <span className="token plain" />
                                        </div>
                                      </div>
                                      <div className="sl-flex">
                                        <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                          4
                                        </div>
                                        <div className="sl-flex-1 sl-break-all">
                                          <span className="token plain">{'    '}</span>
                                          <span className="token string" style={{ color: 'rgb(165, 214, 255)' }}>
                                            "7877-453513-7345"
                                          </span>
                                          <span className="token punctuation" style={{ color: 'inherit' }}>
                                            ,
                                          </span>
                                          <span className="token plain" />
                                        </div>
                                      </div>
                                      <div className="sl-flex">
                                        <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                          5
                                        </div>
                                        <div className="sl-flex-1 sl-break-all">
                                          <span className="token plain">{'    '}</span>
                                          <span className="token string" style={{ color: 'rgb(165, 214, 255)' }}>
                                            "7877-453513-2456"
                                          </span>
                                          <span className="token plain" />
                                        </div>
                                      </div>
                                      <div className="sl-flex">
                                        <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                          6
                                        </div>
                                        <div className="sl-flex-1 sl-break-all">
                                          <span className="token plain">{'  '}</span>
                                          <span className="token punctuation" style={{ color: 'inherit' }}>
                                            ]
                                          </span>
                                          <span className="token plain" />
                                        </div>
                                      </div>
                                      <div className="sl-flex">
                                        <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                          7
                                        </div>
                                        <div className="sl-flex-1 sl-break-all">
                                          <span className="token plain" />
                                          <span className="token punctuation" style={{ color: 'inherit' }}>
                                            {'}'}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </pre>
                                  <style
                                    type="text/css"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        "\n/**\n * Reset the text fill color so that placeholder is visible\n */\n.npm__react-simple-code-editor__textarea:empty {\n  -webkit-text-fill-color: inherit !important;\n}\n\n/**\n * Hack to apply on some CSS on IE10 and IE11\n */\n@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {\n  /**\n    * IE doesn't support '-webkit-text-fill-color'\n    * So we use 'color: transparent' to make the text transparent on IE\n    * Unlike other browsers, it doesn't affect caret color in IE\n    */\n  .npm__react-simple-code-editor__textarea {\n    color: transparent !important;\n  }\n\n  .npm__react-simple-code-editor__textarea::selection {\n    background-color: #accef7 !important;\n    color: transparent !important;\n  }\n}\n",
                                    }}
                                  />
                                </div>
                                <style type="text/css" dangerouslySetInnerHTML={{ __html: '.sl-code-editor[id="react-aria-21-221"] textarea {\n      padding-left: 28px !important;\n      word-break: break-all !important;\n    }' }} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="SendButtonHolder sl-p-4 sl-pt-0">
                        <div className="sl-stack sl-stack--horizontal sl-stack--2 sl-flex sl-flex-row sl-items-center">
                          <button
                            type="button"
                            className="sl-button sl-form-group-border sl-h-sm sl-text-base sl-font-medium sl-px-1.5 sl-bg-primary hover:sl-bg-primary-dark active:sl-bg-primary-darker disabled:sl-bg-canvas-100 sl-text-on-primary disabled:sl-text-body sl-rounded sl-border-transparent sl-border disabled:sl-opacity-70">
                            Send API Request
                          </button>
                          <div className="sl-relative">
                            <button
                              type="button"
                              aria-label="Server"
                              aria-haspopup="true"
                              aria-expanded="false"
                              id="react-aria-21-222"
                              className="sl-button sl-form-group-border sl-h-sm sl-text-base sl-font-normal sl-px-1.5 sl-bg-transparent sl-rounded sl-border-transparent hover:sl-border-input focus:sl-border-primary active:sl-border-primary sl-border disabled:sl-opacity-60">
                              <div className="sl-mr-1">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="server" className="svg-inline--fa fa-server fa-fw fa-sm sl-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                  <path
                                    fill="currentColor"
                                    d="M480 288H32c-17.62 0-32 14.38-32 32v128c0 17.62 14.38 32 32 32h448c17.62 0 32-14.38 32-32v-128C512 302.4 497.6 288 480 288zM352 408c-13.25 0-24-10.75-24-24s10.75-24 24-24s24 10.75 24 24S365.3 408 352 408zM416 408c-13.25 0-24-10.75-24-24s10.75-24 24-24s24 10.75 24 24S429.3 408 416 408zM480 32H32C14.38 32 0 46.38 0 64v128c0 17.62 14.38 32 32 32h448c17.62 0 32-14.38 32-32V64C512 46.38 497.6 32 480 32zM352 152c-13.25 0-24-10.75-24-24S338.8 104 352 104S376 114.8 376 128S365.3 152 352 152zM416 152c-13.25 0-24-10.75-24-24S402.8 104 416 104S440 114.8 440 128S429.3 152 416 152z"
                                  />
                                </svg>
                              </div>
                              <div className="sl-flex sl-flex-1 sl-justify-items-start sl-items-center">
                                <div className="sl-pr-1">Ваш домен</div>
                              </div>
                              <div className="sl-text-xs sl--mr-0.5 sl-ml-1">
                                <div className="sl-pt-0.5 sl-pr-0.5">
                                  <i role="img" aria-hidden="true" className="sl-icon fal fa-chevron-down fa-xs" />
                                </div>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sl-panel sl-outline-none sl-w-full sl-rounded-lg">
                  <div className="sl-panel__titlebar sl-flex sl-items-center sl-relative focus:sl-z-10 sl-text-base sl-leading-none sl-pr-3 sl-pl-4 sl-bg-canvas-200 sl-text-body sl-border-input focus:sl-border-primary sl-select-none">
                    <div className="sl-flex sl-flex-1 sl-items-center sl-h-lg">
                      <div className="sl--ml-2">
                        <button
                          type="button"
                          aria-label="Request Sample Language"
                          aria-haspopup="true"
                          aria-expanded="false"
                          id="react-aria-21-224"
                          className="sl-button sl-form-group-border sl-h-sm sl-text-base sl-font-medium sl-px-1.5 hover:sl-bg-canvas-50 active:sl-bg-canvas-100 sl-text-muted hover:sl-text-body focus:sl-text-body sl-rounded sl-border-transparent sl-border disabled:sl-opacity-70">
                          Request Sample: Shell / cURL
                          <div className="sl-text-xs sl--mr-0.5 sl-ml-1">
                            <i role="img" aria-hidden="true" className="sl-icon fal fa-chevron-down fa-fw" />
                          </div>
                        </button>
                      </div>
                    </div>
                    <button type="button" className="sl-button sl-form-group-border sl-h-sm sl-text-base sl-font-medium sl-px-1.5 hover:sl-bg-canvas-50 active:sl-bg-canvas-100 sl-text-muted hover:sl-text-body focus:sl-text-body sl-rounded sl-border-transparent sl-border disabled:sl-opacity-70">
                      <div className="sl-mx-0">
                        <i role="img" aria-hidden="true" className="sl-icon fal fa-copy fa-fw fa-sm" />
                      </div>
                    </button>
                  </div>
                  <div className="sl-panel__content-wrapper sl-bg-canvas-100">
                    <div className="sl-panel__content sl-p-0">
                      <pre
                        tabIndex={0}
                        aria-label="curl --request POST \
  --url https://your.domain.com/api/order-list \
  --header 'Accept: application/json' \
  --header 'Content-Type: application/json' \
  --header 'signature: ' \
  --data '{
  &quot;orders&quot;: [
    &quot;7877-453513-8790&quot;,
    &quot;7877-453513-7345&quot;,
    &quot;7877-453513-2456&quot;
  ]
}'"
                        className="sl-code-viewer sl-grid sl-overflow-x-hidden sl-overflow-y-hidden sl-relative sl-outline-none sl-group"
                        role="group">
                        <div className="sl-code-viewer__scroller sl-overflow-x-auto sl-overflow-y-auto" style={{ maxHeight: '400px' }}>
                          <div className="sl-code-highlight prism-code language-bash" style={{ padding: '12px 15px', fontFamily: 'var(--font-code)', fontSize: 'var(--fs-code)', lineHeight: 'var(--lh-code)' }}>
                            <div className="sl-flex">
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token function" style={{ color: 'rgb(111, 66, 193)' }}>
                                  curl
                                </span>
                                <span className="token plain"> --request POST </span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  \
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'  '}--url https://your.domain.com/api/order-list </span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  \
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'  '}--header </span>
                                <span className="token string" style={{ color: 'rgb(3, 47, 98)' }}>
                                  'Accept: application/json'
                                </span>
                                <span className="token plain"> </span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  \
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'  '}--header </span>
                                <span className="token string" style={{ color: 'rgb(3, 47, 98)' }}>
                                  'Content-Type: application/json'
                                </span>
                                <span className="token plain"> </span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  \
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'  '}--header </span>
                                <span className="token string" style={{ color: 'rgb(3, 47, 98)' }}>
                                  'signature: '
                                </span>
                                <span className="token plain"> </span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  \
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'  '}--data </span>
                                <span className="token string" style={{ color: 'rgb(3, 47, 98)' }}>
                                  '{'{'}
                                </span>
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token string" style={{ color: 'rgb(3, 47, 98)' }}>
                                  {'  '}"orders": [
                                </span>
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token string" style={{ color: 'rgb(3, 47, 98)' }}>
                                  {'    '}"7877-453513-8790",
                                </span>
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token string" style={{ color: 'rgb(3, 47, 98)' }}>
                                  {'    '}"7877-453513-7345",
                                </span>
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token string" style={{ color: 'rgb(3, 47, 98)' }}>
                                  {'    '}"7877-453513-2456"
                                </span>
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token string" style={{ color: 'rgb(3, 47, 98)' }}>
                                  {'  '}]
                                </span>
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token string" style={{ color: 'rgb(3, 47, 98)' }}>
                                  {'}'}'
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </pre>
                    </div>
                  </div>
                </div>
                <div className="sl-panel sl-outline-none sl-w-full sl-rounded-lg">
                  <div className="sl-panel__titlebar sl-flex sl-items-center sl-relative focus:sl-z-10 sl-text-base sl-leading-none sl-pr-3 sl-pl-4 sl-bg-canvas-200 sl-text-body sl-border-input focus:sl-border-primary sl-select-none">
                    <div className="sl-flex sl-flex-1 sl-items-center sl-h-lg">
                      <span className="sl-text-body">Response Example</span>
                    </div>
                    <button type="button" className="sl-button sl-form-group-border sl-h-sm sl-text-base sl-font-medium sl-px-1.5 hover:sl-bg-canvas-50 active:sl-bg-canvas-100 sl-text-muted hover:sl-text-body focus:sl-text-body sl-rounded sl-border-transparent sl-border disabled:sl-opacity-70">
                      <div className="sl-mx-0">
                        <i role="img" aria-hidden="true" className="sl-icon fal fa-copy fa-fw fa-sm" />
                      </div>
                    </button>
                  </div>
                  <div className="sl-panel__content-wrapper sl-bg-canvas-100">
                    <div className="sl-panel__content sl-p-0">
                      <pre
                        tabIndex={0}
                        aria-label='[
  {
    "id": 29,
    "status": "in-progress",
    "orderId": "7877-453513-8790"
  },
  {
    "id": 30,
    "status": "completed",
    "orderId": "7877-453513-6456"
  },
  {
    "id": 31,
    "status": "error",
    "orderId": "7877-453513-2434"
  }
]'
                        className="sl-code-viewer sl-grid sl-overflow-x-hidden sl-overflow-y-hidden sl-relative sl-outline-none sl-group"
                        role="group">
                        <div className="sl-code-viewer__scroller sl-overflow-x-auto sl-overflow-y-auto" style={{ maxHeight: '500px' }}>
                          <div className="sl-code-highlight prism-code language-json" style={{ padding: '12px 15px', fontFamily: 'var(--font-code)', fontSize: 'var(--fs-code)', lineHeight: 'var(--lh-code)' }}>
                            <div className="sl-flex">
                              <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                1
                              </div>
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  [
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                2
                              </div>
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'  '}</span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  {'{'}
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                3
                              </div>
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'    '}</span>
                                <span className="token property" style={{ color: 'rgb(24, 54, 145)' }}>
                                  "id"
                                </span>
                                <span className="token operator" style={{ color: 'rgb(215, 58, 73)' }}>
                                  :
                                </span>
                                <span className="token plain"> </span>
                                <span className="token number" style={{ color: 'rgb(0, 92, 197)' }}>
                                  29
                                </span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  ,
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                4
                              </div>
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'    '}</span>
                                <span className="token property" style={{ color: 'rgb(24, 54, 145)' }}>
                                  "status"
                                </span>
                                <span className="token operator" style={{ color: 'rgb(215, 58, 73)' }}>
                                  :
                                </span>
                                <span className="token plain"> </span>
                                <span className="token string" style={{ color: 'rgb(3, 47, 98)' }}>
                                  "in-progress"
                                </span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  ,
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                5
                              </div>
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'    '}</span>
                                <span className="token property" style={{ color: 'rgb(24, 54, 145)' }}>
                                  "orderId"
                                </span>
                                <span className="token operator" style={{ color: 'rgb(215, 58, 73)' }}>
                                  :
                                </span>
                                <span className="token plain"> </span>
                                <span className="token string" style={{ color: 'rgb(3, 47, 98)' }}>
                                  "7877-453513-8790"
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                6
                              </div>
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'  '}</span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  {'}'}
                                </span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  ,
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                7
                              </div>
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'  '}</span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  {'{'}
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                8
                              </div>
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'    '}</span>
                                <span className="token property" style={{ color: 'rgb(24, 54, 145)' }}>
                                  "id"
                                </span>
                                <span className="token operator" style={{ color: 'rgb(215, 58, 73)' }}>
                                  :
                                </span>
                                <span className="token plain"> </span>
                                <span className="token number" style={{ color: 'rgb(0, 92, 197)' }}>
                                  30
                                </span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  ,
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                9
                              </div>
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'    '}</span>
                                <span className="token property" style={{ color: 'rgb(24, 54, 145)' }}>
                                  "status"
                                </span>
                                <span className="token operator" style={{ color: 'rgb(215, 58, 73)' }}>
                                  :
                                </span>
                                <span className="token plain"> </span>
                                <span className="token string" style={{ color: 'rgb(3, 47, 98)' }}>
                                  "completed"
                                </span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  ,
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                10
                              </div>
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'    '}</span>
                                <span className="token property" style={{ color: 'rgb(24, 54, 145)' }}>
                                  "orderId"
                                </span>
                                <span className="token operator" style={{ color: 'rgb(215, 58, 73)' }}>
                                  :
                                </span>
                                <span className="token plain"> </span>
                                <span className="token string" style={{ color: 'rgb(3, 47, 98)' }}>
                                  "7877-453513-6456"
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                11
                              </div>
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'  '}</span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  {'}'}
                                </span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  ,
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                12
                              </div>
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'  '}</span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  {'{'}
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                13
                              </div>
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'    '}</span>
                                <span className="token property" style={{ color: 'rgb(24, 54, 145)' }}>
                                  "id"
                                </span>
                                <span className="token operator" style={{ color: 'rgb(215, 58, 73)' }}>
                                  :
                                </span>
                                <span className="token plain"> </span>
                                <span className="token number" style={{ color: 'rgb(0, 92, 197)' }}>
                                  31
                                </span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  ,
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                14
                              </div>
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'    '}</span>
                                <span className="token property" style={{ color: 'rgb(24, 54, 145)' }}>
                                  "status"
                                </span>
                                <span className="token operator" style={{ color: 'rgb(215, 58, 73)' }}>
                                  :
                                </span>
                                <span className="token plain"> </span>
                                <span className="token string" style={{ color: 'rgb(3, 47, 98)' }}>
                                  "error"
                                </span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  ,
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                15
                              </div>
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'    '}</span>
                                <span className="token property" style={{ color: 'rgb(24, 54, 145)' }}>
                                  "orderId"
                                </span>
                                <span className="token operator" style={{ color: 'rgb(215, 58, 73)' }}>
                                  :
                                </span>
                                <span className="token plain"> </span>
                                <span className="token string" style={{ color: 'rgb(3, 47, 98)' }}>
                                  "7877-453513-2434"
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                16
                              </div>
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'  '}</span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  {'}'}
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                17
                              </div>
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain" />
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  ]
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </pre>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThreeStepPage;
