import React, { useEffect, useState } from 'react';
const StartHelperPage = () => {
  return (
    <>
      <div data-test="project-page" className="sl-w-full sl-mx-auto sl-px-20 sl-pt-20 sl-pb-8" style={{ maxWidth: '1500px' }}>
        <div className="HttpService sl-mb-10">
          <div className="sl-flex sl-justify-between sl-items-center">
            <div className="sl-relative">
              <h1 className="sl-text-5xl sl-leading-tight sl-font-prose sl-font-semibold sl-mb-4 sl-text-heading">API помощники</h1>
            </div>
          </div>
          <div className="sl-relative sl-mb-5">
            <span className="sl-badge sl-max-w-full sl-h-xs sl-text-paragraph-tiny sl-whitespace-nowrap sl-px-1.5 sl-py-0.5 sl-bg-canvas-100 sl-text-paragraph sl-rounded-full sl-border-input sl-border-0" style={{ backgroundColor: 'rgb(41, 55, 66)', color: 'rgb(255, 255, 255)' }}>
              <div className="sl-flex sl-justify-items-start sl-items-center sl-w-full">
                <div className="sl-flex sl-overflow-x-hidden sl-flex-shrink">
                  <div className="sl-overflow-x-hidden sl-w-auto sl-truncate sl-whitespace-nowrap">
                    <span>v1.0</span>
                  </div>
                </div>
              </div>
            </span>
          </div>
          <div className="sl-stack sl-stack--vertical sl-stack--6 sl-flex sl-flex-col sl-items-stretch">
            <div data-test="servers" className="sl-panel sl-outline-none BaseURLContent sl-inverted sl-w-full sl-rounded-lg">
              <div className="sl-panel__titlebar sl-flex sl-items-center sl-relative focus:sl-z-10 sl-text-base sl-leading-none sl-whitespace-nowrap sl-pr-4 sl-pl-4 sl-bg-canvas-200 sl-text-body sl-border-input focus:sl-border-primary sl-select-none">
                <div className="sl-flex sl-flex-1 sl-items-center sl-h-lg">API Base URL</div>
              </div>
              <div className="sl-panel__content-wrapper sl-bg-canvas-100">
                <div className="sl-flex sl-flex-col sl-w-full sl-p-4">
                  <div className="sl-stack sl-stack--vertical sl-stack--1 sl-flex sl-flex-col sl-items-stretch">
                    <div data-test="server-row" className="sl-panel sl-outline-none ServerInfo sl-w-full">
                      <div className="sl-panel__titlebar sl-flex sl-items-center sl-relative focus:sl-z-10 sl-text-base sl-leading-none sl-whitespace-nowrap sl-pr-4 sl-pl-4 sl-bg-canvas-200 sl-text-body sl-border-input focus:sl-border-primary sl-select-none">
                        <div className="sl-flex sl-flex-1 sl-items-center sl-h-lg">
                          <span className="sl-font-bold sl-pr-2 sl-pl-0">Ваше API:</span>
                          <span aria-label="Ваше API" className="sl-whitespace-normal sl-py-2" style={{ wordBreak: 'break-word' }}>
                            <span className="sl-font-normal">https://api.malinka</span>
                          </span>
                        </div>
                      </div>
                      <div className="sl-panel__content-wrapper sl-bg-canvas-100" />
                    </div>
                    <div className="sl-self-stretch sl-border-t" />
                    <div data-test="server-row" className="sl-panel sl-outline-none ServerInfo sl-w-full">
                      <div className="sl-panel__titlebar sl-flex sl-items-center sl-relative focus:sl-z-10 sl-text-base sl-leading-none sl-whitespace-nowrap sl-pr-4 sl-pl-4 sl-bg-canvas-200 sl-text-body sl-border-input focus:sl-border-primary sl-select-none">
                        <div className="sl-flex sl-flex-1 sl-items-center sl-h-lg">
                          <span className="sl-font-bold sl-pr-2 sl-pl-0">Mock Server:</span>
                          <span aria-label="Mock Server" className="sl-whitespace-normal sl-py-2" style={{ wordBreak: 'break-word' }}>
                            <span className="sl-font-normal">https://stoplight.io/mocks/malinka/malinka/356211315</span>
                          </span>
                        </div>
                      </div>
                      <div className="sl-panel__content-wrapper sl-bg-canvas-100" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div data-test="security" />
            <div data-test="additional-info" />
          </div>
          <div className="sl-relative">
            <div className="sl-prose sl-markdown-viewer sl-my-5">
              <p>
                API помощники служат для проверки формата возвращаемых данных запроса. <br />
                При правильном формате возвращет статус{' '}
                <code className="sl-font-mono sl-font-medium sl-mx-0.5 sl-px-1 sl-py-0.5 sl-bg-code sl-text-on-code sl-rounded sl-border" style={{ fontSize: '0.8125em' }}>
                  200
                </code>{' '}
                и ответ{' '}
                <code className="sl-font-mono sl-font-medium sl-mx-0.5 sl-px-1 sl-py-0.5 sl-bg-code sl-text-on-code sl-rounded sl-border" style={{ fontSize: '0.8125em' }}>
                  ОК
                </code>
                При наличии ошибок возвращает статус{' '}
                <code className="sl-font-mono sl-font-medium sl-mx-0.5 sl-px-1 sl-py-0.5 sl-bg-code sl-text-on-code sl-rounded sl-border" style={{ fontSize: '0.8125em' }}>
                  400
                </code>{' '}
                и ответ поля, где были допущены ошибки
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StartHelperPage;
