import React, { useEffect, useState } from 'react';
const OneHelperPage = () => {
  return (
    <div data-test="project-page" className="sl-w-full sl-mx-auto sl-px-20 sl-pt-20 sl-pb-8" style={{ maxWidth: '1500px' }}>
      <div className="sl-stack sl-stack--vertical sl-stack--8 HttpOperation sl-flex sl-flex-col sl-items-stretch sl-w-full">
        <div className="sl-stack sl-stack--vertical sl-stack--5 sl-flex sl-flex-col sl-items-stretch">
          <div className="sl-relative">
            <div className="sl-stack sl-stack--horizontal sl-stack--5 sl-flex sl-flex-row sl-items-center">
              <h1 className="sl-text-5xl sl-leading-tight sl-font-prose sl-font-semibold sl-text-heading">[Помощник] Валидация списка статусов</h1>
              <div className="sl-stack sl-stack--horizontal sl-stack--2 sl-flex sl-flex-row sl-items-center" />
            </div>
          </div>
          <div className="sl-relative">
            <div>
              <div title="https://stoplight.io/mocks/malinka/malinka/356211315/validate-status-list" className="sl-stack sl-stack--horizontal sl-stack--3 sl-inline-flex sl-flex-row sl-items-center sl-max-w-full sl-font-mono sl-py-2 sl-pr-4 sl-pl-2.5 sl-bg-canvas-50 sl-rounded-lg">
                <div className="sl-text-lg sl-font-semibold sl-uppercase sl-px-2.5 sl-py-1 sl-bg-primary sl-text-on-primary sl-rounded-lg">post</div>
                <div className="sl-flex sl-overflow-x-hidden sl-text-lg sl-select-all">
                  <div dir="rtl" className="sl-overflow-x-hidden sl-truncate sl-text-muted">
                    <span dir="ltr" style={{ unicodeBidi: 'bidi-override' }}>
                      https://stoplight.io/mocks/malinka/malinka/356211315
                    </span>
                  </div>
                  <div className="sl-flex-1 sl-font-semibold">/validate-status-list</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sl-flex">
          <div data-testid="two-column-left" className="sl-flex-1 sl-w-0">
            <div className="sl-stack sl-stack--vertical sl-stack--10 sl-flex sl-flex-col sl-items-stretch">
              <div className="sl-relative">
                <div className="sl-prose sl-markdown-viewer HttpOperation__Description">
                  <p>
                    С помощью этой API можно проверить валидность тела{' '}
                    <code className="sl-font-mono sl-font-medium sl-mx-0.5 sl-px-1 sl-py-0.5 sl-bg-code sl-text-on-code sl-rounded sl-border" style={{ fontSize: '0.8125em' }}>
                      /status-list
                    </code>
                    . Для этого добавьте в тело запроса{' '}
                    <code className="sl-font-mono sl-font-medium sl-mx-0.5 sl-px-1 sl-py-0.5 sl-bg-code sl-text-on-code sl-rounded sl-border" style={{ fontSize: '0.8125em' }}>
                      url
                    </code>
                    , который хотите проверить
                  </p>
                </div>
              </div>
              <div className="sl-stack sl-stack--vertical sl-stack--8 sl-flex sl-flex-col sl-items-stretch">
                <div className="sl-flex sl-w-full">
                  <h2 id="Request" aria-label="Request" className="sl-link-heading sl-py-1 sl-pr-6 sl-text-4xl sl-leading-tight sl-font-prose sl-font-bold sl-text-heading">
                    <a href="#Request" className="sl-link sl-link-heading__link sl-inline-flex sl-items-center sl-text-current">
                      <div>Request</div>
                      <div className="sl-link-heading__icon sl-text-base sl-ml-4 sl-text-muted">
                        <i role="img" aria-hidden="true" className="sl-icon fal fa-link" />
                      </div>
                    </a>
                  </h2>
                  <div className="sl-flex sl-flex-grow sl-self-center sl-py-1" style={{ minWidth: '0px' }} />
                </div>
                <div className="sl-stack sl-stack--vertical sl-stack--6 sl-flex sl-flex-col sl-items-stretch">
                  <div className="sl-flex sl-w-full">
                    <h3 id="request-body" aria-label="Body" className="sl-link-heading sl-py-1 sl-pr-6 sl-text-2xl sl-leading-snug sl-font-prose sl-font-semibold sl-text-heading">
                      <a href="#request-body" className="sl-link sl-link-heading__link sl-inline-flex sl-items-center sl-text-current">
                        <div>Body</div>
                        <div className="sl-link-heading__icon sl-text-base sl-ml-4 sl-text-muted">
                          <i role="img" aria-hidden="true" className="sl-icon fal fa-link" />
                        </div>
                      </a>
                    </h3>
                    <div className="sl-flex sl-flex-grow sl-self-center sl-py-1" style={{ minWidth: '0px' }}>
                      <div className="sl-flex sl-flex-1 sl-justify-end">
                        <div className="sl-select sl-relative">
                          <div aria-hidden="true" style={{ border: '0px', clip: 'rect(0px, 0px, 0px, 0px)', clipPath: 'inset(50%)', height: '1px', margin: '0px -1px -1px 0px', overflow: 'hidden', padding: '0px', position: 'absolute', width: '1px', whiteSpace: 'nowrap' }}>
                            <input type="text" tabIndex={0} style={{ fontSize: '16px' }} />
                            <label>
                              <select size={1} tabIndex={-1}>
                                <option />
                                <option value={0}>application/json</option>
                              </select>
                            </label>
                          </div>
                          <div className="sl-relative">
                            <button
                              aria-label="Request Body Content Type"
                              aria-haspopup="listbox"
                              aria-expanded="false"
                              id="react-aria-0-8"
                              aria-labelledby="react-aria-0-8 react-aria-0-10"
                              type="button"
                              className="sl-button sl-form-group-border sl-w-full sl-h-sm sl-text-base sl-font-normal sl-px-1.5 sl-bg-transparent sl-rounded sl-border-transparent hover:sl-border-input focus:sl-border-primary active:sl-border-primary sl-border disabled:sl-opacity-60">
                              <div className="sl-flex sl-flex-1 sl-justify-items-start sl-items-center">
                                <div className="sl-pr-1">application/json</div>
                              </div>
                              <div className="sl-text-xs sl--mr-0.5 sl-ml-1">
                                <div className="sl-pt-0.5 sl-pr-0.5">
                                  <i role="img" aria-hidden="true" className="sl-icon fal fa-chevron-down fa-xs" />
                                </div>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className id="mosaic-provider-react-aria-1-1">
                    <div data-overlay-container="true" className>
                      <div className="JsonSchemaViewer">
                        <div />
                        <div data-level={0} className="sl-text-sm sl-ml-px sl-border-l">
                          <div data-id="3ml1se13t8m61" data-test="schema-row" className="sl-flex sl-relative sl-max-w-full sl-py-2 sl-pl-3">
                            <div className="sl-w-3 sl-mt-2 sl-mr-3 sl--ml-3 sl-border-t" />
                            <div className="sl-stack sl-stack--vertical sl-stack--1 sl-flex sl-flex-1 sl-flex-col sl-items-stretch sl-max-w-full">
                              <div className="sl-flex sl-items-center sl-max-w-full">
                                <div className="sl-flex sl-items-baseline sl-text-base">
                                  <div data-test="property-name-url" className="sl-font-mono sl-font-semibold sl-mr-2">
                                    url
                                  </div>
                                  <span data-test="property-type" className="sl-truncate sl-text-muted">
                                    string
                                  </span>
                                </div>
                              </div>
                              <div data-test="property-description" className="sl-prose sl-markdown-viewer " style={{ fontSize: '12px' }}>
                                <p>
                                  Ссылка{' '}
                                  <code className="sl-font-mono sl-font-medium sl-mx-0.5 sl-px-1 sl-py-0.5 sl-bg-code sl-text-on-code sl-rounded sl-border" style={{ fontSize: '0.8125em' }}>
                                    /status-list
                                  </code>{' '}
                                  которую нужно проверить на валидность ответа
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sl-stack sl-stack--vertical sl-stack--6 sl-flex sl-flex-col sl-items-stretch">
                <div className="sl-inverted">
                  <div className="sl-overflow-y-hidden sl-rounded-lg">
                    <div className="TryItPanel sl-bg-canvas-100 sl-rounded-lg">
                      <div className="sl-pb-1">
                        <div className="sl-panel sl-outline-none sl-w-full">
                          <div
                            aria-expanded="true"
                            tabIndex={0}
                            className="sl-panel__titlebar sl-flex sl-items-center sl-relative focus:sl-z-10 sl-text-base sl-leading-none sl-pr-4 sl-pl-3 sl-bg-canvas-200 sl-text-body sl-border-input focus:sl-border-primary sl-cursor-pointer sl-select-none"
                            role="button">
                            <div className="sl-flex sl-flex-1 sl-items-center sl-h-lg">
                              <div className="sl-flex sl-items-center sl-mr-1.5">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" className="svg-inline--fa fa-caret-down fa-fw sl-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                  <path fill="currentColor" d="M310.6 246.6l-127.1 128C176.4 380.9 168.2 384 160 384s-16.38-3.125-22.63-9.375l-127.1-128C.2244 237.5-2.516 223.7 2.438 211.8S19.07 192 32 192h255.1c12.94 0 24.62 7.781 29.58 19.75S319.8 237.5 310.6 246.6z" />
                                </svg>
                              </div>
                              Body
                            </div>
                          </div>
                          <div className="sl-panel__content-wrapper sl-bg-canvas-100" role="region">
                            <div className="TextRequestBody sl-p-4">
                              <div id="react-aria-1-17" className="sl-code-editor" style={{ fontFamily: 'var(--font-code)', fontSize: '12px', lineHeight: 'var(--lh-code)' }}>
                                <div style={{ position: 'relative', textAlign: 'left', boxSizing: 'border-box', padding: '0px', overflow: 'hidden', fontSize: '12px' }}>
                                  <textarea
                                    className="npm__react-simple-code-editor__textarea sl-border-transparent hover:sl-border-input focus:sl-border-primary"
                                    autoCapitalize="off"
                                    autoComplete="off"
                                    autoCorrect="off"
                                    spellCheck="false"
                                    data-gramm="false"
                                    style={{
                                      margin: '0px',
                                      background: 'none',
                                      boxSizing: 'inherit',
                                      display: 'inherit',
                                      fontFamily: 'inherit',
                                      fontSize: 'inherit',
                                      fontStyle: 'inherit',
                                      fontVariantLigatures: 'inherit',
                                      fontWeight: 'inherit',
                                      letterSpacing: 'inherit',
                                      lineHeight: 'inherit',
                                      tabSize: 'inherit',
                                      textIndent: 'inherit',
                                      textRendering: 'inherit',
                                      textTransform: 'inherit',
                                      whiteSpace: 'pre-wrap',
                                      wordBreak: 'keep-all',
                                      overflowWrap: 'break-word',
                                      position: 'absolute',
                                      top: '0px',
                                      left: '0px',
                                      height: '100%',
                                      width: '100%',
                                      resize: 'none',
                                      color: 'inherit',
                                      overflow: 'hidden',
                                      WebkitFontSmoothing: 'antialiased',
                                      WebkitTextFillColor: 'transparent',
                                      padding: '0px',
                                    }}
                                    defaultValue={'{\n  "url": "string"\n}'}
                                  />
                                  <pre
                                    aria-hidden="true"
                                    style={{
                                      margin: '0px',
                                      background: 'none',
                                      boxSizing: 'inherit',
                                      display: 'inherit',
                                      fontFamily: 'inherit',
                                      fontSize: 'inherit',
                                      fontStyle: 'inherit',
                                      fontVariantLigatures: 'inherit',
                                      fontWeight: 'inherit',
                                      letterSpacing: 'inherit',
                                      lineHeight: 'inherit',
                                      tabSize: 'inherit',
                                      textIndent: 'inherit',
                                      textRendering: 'inherit',
                                      textTransform: 'inherit',
                                      whiteSpace: 'pre-wrap',
                                      wordBreak: 'keep-all',
                                      overflowWrap: 'break-word',
                                      position: 'relative',
                                      pointerEvents: 'none',
                                      padding: '0px',
                                    }}>
                                    <div className="sl-code-highlight prism-code language-json" style={{ fontSize: '12px' }}>
                                      <div className="sl-flex">
                                        <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                          1
                                        </div>
                                        <div className="sl-flex-1 sl-break-all">
                                          <span className="token punctuation" style={{ color: 'inherit' }}>
                                            {'{'}
                                          </span>
                                          <span className="token plain" />
                                        </div>
                                      </div>
                                      <div className="sl-flex">
                                        <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                          2
                                        </div>
                                        <div className="sl-flex-1 sl-break-all">
                                          <span className="token plain">{'  '}</span>
                                          <span className="token property" style={{ color: 'rgb(128, 203, 196)' }}>
                                            "url"
                                          </span>
                                          <span className="token operator" style={{ color: 'rgb(255, 123, 114)' }}>
                                            :
                                          </span>
                                          <span className="token plain"> </span>
                                          <span className="token string" style={{ color: 'rgb(165, 214, 255)' }}>
                                            "string"
                                          </span>
                                          <span className="token plain" />
                                        </div>
                                      </div>
                                      <div className="sl-flex">
                                        <div className="sl-code-highlight__ln sl-flex-shrink-0 sl-select-none sl-opacity-50" style={{ width: '28px', fontSize: '0.9em', paddingTop: '0.1em', lineHeight: 'var(--lh-code)' }}>
                                          3
                                        </div>
                                        <div className="sl-flex-1 sl-break-all">
                                          <span className="token plain" />
                                          <span className="token punctuation" style={{ color: 'inherit' }}>
                                            {'}'}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </pre>
                                  <style
                                    type="text/css"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        "\n/**\n * Reset the text fill color so that placeholder is visible\n */\n.npm__react-simple-code-editor__textarea:empty {\n  -webkit-text-fill-color: inherit !important;\n}\n\n/**\n * Hack to apply on some CSS on IE10 and IE11\n */\n@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {\n  /**\n    * IE doesn't support '-webkit-text-fill-color'\n    * So we use 'color: transparent' to make the text transparent on IE\n    * Unlike other browsers, it doesn't affect caret color in IE\n    */\n  .npm__react-simple-code-editor__textarea {\n    color: transparent !important;\n  }\n\n  .npm__react-simple-code-editor__textarea::selection {\n    background-color: #accef7 !important;\n    color: transparent !important;\n  }\n}\n",
                                    }}
                                  />
                                </div>
                                <style type="text/css" dangerouslySetInnerHTML={{ __html: '.sl-code-editor[id="react-aria-1-17"] textarea {\n      padding-left: 28px !important;\n      word-break: break-all !important;\n    }' }} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="SendButtonHolder sl-p-4 sl-pt-0">
                        <div className="sl-stack sl-stack--horizontal sl-stack--2 sl-flex sl-flex-row sl-items-center">
                          <button
                            type="button"
                            className="sl-button sl-form-group-border sl-h-sm sl-text-base sl-font-medium sl-px-1.5 sl-bg-primary hover:sl-bg-primary-dark active:sl-bg-primary-darker disabled:sl-bg-canvas-100 sl-text-on-primary disabled:sl-text-body sl-rounded sl-border-transparent sl-border disabled:sl-opacity-70">
                            Send API Request
                          </button>
                          <div>
                            <div className="sl-relative">
                              <button
                                type="button"
                                aria-label="Mock settings"
                                aria-haspopup="true"
                                aria-expanded="false"
                                id="react-aria-1-18"
                                className="sl-button sl-form-group-border sl-h-sm sl-text-base sl-font-normal sl-px-1.5 sl-bg-transparent sl-rounded sl-border-transparent hover:sl-border-input focus:sl-border-primary active:sl-border-primary sl-border disabled:sl-opacity-60">
                                <div className="sl-flex sl-flex-1 sl-justify-items-start sl-items-center">
                                  <div className="sl-pr-1">Mock Settings</div>
                                </div>
                                <div className="sl-text-xs sl--mr-0.5 sl-ml-1">
                                  <div className="sl-pt-0.5 sl-pr-0.5">
                                    <i role="img" aria-hidden="true" className="sl-icon fal fa-chevron-down fa-xs" />
                                  </div>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sl-panel sl-outline-none sl-w-full sl-rounded-lg">
                  <div className="sl-panel__titlebar sl-flex sl-items-center sl-relative focus:sl-z-10 sl-text-base sl-leading-none sl-pr-3 sl-pl-4 sl-bg-canvas-200 sl-text-body sl-border-input focus:sl-border-primary sl-select-none">
                    <div className="sl-flex sl-flex-1 sl-items-center sl-h-lg">
                      <div className="sl--ml-2">
                        <button
                          type="button"
                          aria-label="Request Sample Language"
                          aria-haspopup="true"
                          aria-expanded="false"
                          id="react-aria-1-20"
                          className="sl-button sl-form-group-border sl-h-sm sl-text-base sl-font-medium sl-px-1.5 hover:sl-bg-canvas-50 active:sl-bg-canvas-100 sl-text-muted hover:sl-text-body focus:sl-text-body sl-rounded sl-border-transparent sl-border disabled:sl-opacity-70">
                          Request Sample: Shell / cURL
                          <div className="sl-text-xs sl--mr-0.5 sl-ml-1">
                            <i role="img" aria-hidden="true" className="sl-icon fal fa-chevron-down fa-fw" />
                          </div>
                        </button>
                      </div>
                    </div>
                    <button type="button" className="sl-button sl-form-group-border sl-h-sm sl-text-base sl-font-medium sl-px-1.5 hover:sl-bg-canvas-50 active:sl-bg-canvas-100 sl-text-muted hover:sl-text-body focus:sl-text-body sl-rounded sl-border-transparent sl-border disabled:sl-opacity-70">
                      <div className="sl-mx-0">
                        <i role="img" aria-hidden="true" className="sl-icon fal fa-copy fa-fw fa-sm" />
                      </div>
                    </button>
                  </div>
                  <div className="sl-panel__content-wrapper sl-bg-canvas-100">
                    <div className="sl-panel__content sl-p-0">
                      <pre
                        tabIndex={0}
                        aria-label="curl --request POST \
  --url https://stoplight.io/mocks/malinka/malinka/356211315/validate-status-list \
  --header 'Content-Type: application/json' \
  --data '{
  &quot;url&quot;: &quot;string&quot;
}'"
                        className="sl-code-viewer sl-grid sl-overflow-x-hidden sl-overflow-y-hidden sl-relative sl-outline-none sl-group"
                        role="group">
                        <div className="sl-code-viewer__scroller sl-overflow-x-auto sl-overflow-y-auto" style={{ maxHeight: '400px' }}>
                          <div className="sl-code-highlight prism-code language-bash" style={{ padding: '12px 15px', fontFamily: 'var(--font-code)', fontSize: 'var(--fs-code)', lineHeight: 'var(--lh-code)' }}>
                            <div className="sl-flex">
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token function" style={{ color: 'rgb(111, 66, 193)' }}>
                                  curl
                                </span>
                                <span className="token plain"> --request POST </span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  \
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'  '}--url https://stoplight.io/mocks/malinka/malinka/356211315/validate-status-list </span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  \
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'  '}--header </span>
                                <span className="token string" style={{ color: 'rgb(3, 47, 98)' }}>
                                  'Content-Type: application/json'
                                </span>
                                <span className="token plain"> </span>
                                <span className="token punctuation" style={{ color: 'rgb(51, 51, 51)' }}>
                                  \
                                </span>
                                <span className="token plain" />
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token plain">{'  '}--data </span>
                                <span className="token string" style={{ color: 'rgb(3, 47, 98)' }}>
                                  '{'{'}
                                </span>
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token string" style={{ color: 'rgb(3, 47, 98)' }}>
                                  {'  '}"url": "string"
                                </span>
                              </div>
                            </div>
                            <div className="sl-flex">
                              <div className="sl-flex-1 sl-break-all">
                                <span className="token string" style={{ color: 'rgb(3, 47, 98)' }}>
                                  {'}'}'
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </pre>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OneHelperPage;
